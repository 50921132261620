<template>
    <Vue3DraggableResizable
        :id="'box' + id"
        :classNameDraggable="'box ' + boxClass + 'Bg'"
        :class="boxClass + 'Bg'"
        :initW="w2"
        :initH="h2"
        v-model:x="x"
        v-model:y="y"
        v-model:w="w2"
        v-model:h="h2"
        :parent="true"
        :draggable="isDragable"
        :resizable="objType === 'textarea' ? true : false" 
        @resizing="resizeEndHandle" 
        classNameActive="boxSelected" 
        style="z-index: 2"
    >
        <div v-if="objType === 'name'" @click="openAttr()" class="ps-1 p-0" style="height: 100%">
            <fa icon="user-circle" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
            <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight+ '; font-style: ' + fontStyle">Name</span>
        </div>
  
        <div v-if="objType === 'email'" @click="openAttr()" class="ps-1 p-0" style="height: 100%">
            <fa icon="user-circle" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
            <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle">Email</span>
        </div>
  
        <div v-if="objType === 'idPassport'" @click="openAttr()" class="ps-1 p-0" style="height: 100%">
            <fa icon="user-circle" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
            <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle">ID/Passport</span>
        </div>
  
        <div v-if="objType === 'textbox'" @click="openAttr()" class="ps-1 p-0" style="height: 100%">
            <fa icon="font" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px"/> 
            <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle">{{ label }}</span>
        </div>
  
        <div v-if="objType === 'textarea'" @click="openAttr()" class="ps-1 p-0" style="height: 100%">
            <fa icon="bars" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px"/> 
            <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle">{{ label }}</span>
        </div>
  
        <div v-if="objType === 'checkbox'" @click="openAttr()" class="ps-1 p-0" style="height: 100%">
            <fa icon="check-square" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px"/> 
            <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle">{{ label }}</span>
        </div>
  
        <div v-if="objType === 'dateSigned'" @click="openAttr()" class="ps-1 p-0" style="height: 100%">
            <fa icon="calendar-alt" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px"/>
            <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle">Date Signed</span>
            <!-- <Datepicker :modelValue="date" @update:modelValue="setDate" :enableTimePicker="false" position="left" :format="title === 'Date (YYYY-MM-DD)' ? 'yyyy-MM-dd' : 'dd-MMM-yyyy'" class="boxCal" autoApply></Datepicker> -->
        </div>
  
        <div v-if="objType === 'fullSignature'" class="ps-1 p-0">
            <fa icon="signature" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
            <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle">Full Signature</span>
        </div>
  
        <div v-if="objType === 'initial'" @click="openAttr()" class="ps-1 p-0">
            <fa icon="pen-nib" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
            <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle">Initial</span> <div class="small"><i>(Appear in all pages)</i></div>
        </div>
  
        <div v-if="objType === 'stamp'" class="ps-1 p-0">
            <fa icon="stamp" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
            <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle">Company Stamp</span>
        </div>
  
        <div v-if="objType === 'comboSignature'" class="ps-1 p-0">
            <fa icon="object-group" size="lg" class="me-1 ps-0 align-top" style="margin-top: 0px" /> 
            <span class="p-0 align-top" :style="'line-height: '+ (fontSize*scale) +'pt; font-family: ' + fontFamily + '; font-size: ' + (fontSize*scale) + 'pt; font-weight: ' + fontWeight + '; font-style: ' + fontStyle">Combo Signature</span>
  
            <div class="text-center"><fa icon="signature" style="font-size: 50px" /></div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="comboFullLegalName" value="true">
                <label class="form-check-label">Full legal name</label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="comboDesignation" value="true">
                <label class="form-check-label">Designation</label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="comboOrganization" value="true">
                <label class="form-check-label">Organization</label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="comboNRIC" value="true">
                <label class="form-check-label">NRIC</label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="comboPassport" value="true">
                <label class="form-check-label">Passport</label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="comboDateSigned" value="true">
                <label class="form-check-label">Date Signed</label>
            </div>
        </div>
  
        <div class="px-2 position-absolute top-0 end-0" style="padding-top: 2px;" >
            <fa icon="times" @click="delBox(id)" style="cursor: pointer; padding-top: 1px"/>
        </div>
  
        <div v-if="((objType !== 'fullSignature' && objType !== 'initial' && objType !== 'stamp' && objType !== 'comboSignature'))"  @click="openAttr()" class="bg-secondary text-white divFont">
          <table width="100%" class="text-center" border="0">
              <tr>
                  <td width="16%">
                      <span class="isLink" @click="changeFont('-')" :class="fontSize === 8 ? '' : 'isLink'">
                          <fa icon="minus" size="lg" />
                      </span>
                  </td>
                  <td width="25%">{{ fontSize }}</td>
                  <td width="20%" class="pe-2">
                      <span class="float-end" @click="changeFont('+')" :class="fontSize === 18 ? '' : 'isLink'">
                          <fa icon="plus" size="lg" />
                      </span>
                  </td>
                  <td width="4%" class="border-end"></td>
                  <td width="35%" class="text-end">
                      <span @click="toggleBold()" class="isLink px-1 me-2" :class="isBold === true || isBold === 'true' ? 'border' : ''" style="font-size: 18px; font-family: 'Courier New', Courier, monospace;">B</span>
                      <span @click="toggleItalic()" class="isLink px-1" :class="isItalic === true || isItalic === 'true' ? 'border' : ''" style="font-size: 18px; font-style: italic; font-family: 'Courier New', Courier, monospace">I</span>
                  </td>
              </tr>
          </table>
        </div>
  
        <!-- <div class="text-primary mt-1">label : {{label}}</div> -->
        <!-- <div class="text-danger mt-1">scale: {{ scale }} </div> -->
        <!-- <div class="text-info mt-1 fw-bold">{{x}}, {{y}} | {{ scale }} </div> -->
        <!-- <div class="text-primary mt-1">{{x/scale}}, {{y/scale}}</div> -->
        <!-- <div class="text-primary mt-1">{{x/uiRatio}}, {{y/uiRatio}}</div> -->
        <!-- <div class="text-primary"> id:box{{ id }} - {{objType}}</div> -->
        <!-- <div class="text-primary"> id:box{{ id }} - {{x}}x{{y}} pg{{page}} sg{{signee}}<br>{{objType}} | {{boxClass}} | {{data}}</div> -->
        <!-- <div class="text-primary">{{data}}</div> -->
        <!-- <div class="text-danger">{{w}} x  {{h}}</div> -->
        <!-- <div class="text-primary"> {{boxClass}} {{isDragable}} {{typeof isDragable}}</div> -->
        <!-- <div class="text-primary small">{{boxClass}}</div> -->
        <!-- <div class="small ps-3"> {{fontSize}}, {{ fontFamily }}</div> -->
        <!-- <div class="small text-danger ps-3">m: {{maxChar}} | size: {{fontSize}}</div> -->
        <!-- <div class="small ps-3">{{fontFamily}} | {{fontSize}} | {{fontWeight}} | {{fontStyle}}</div>  -->
        <!-- <div class="small ps-3" style="font-family: Courier; font-size: 9pt">Courier, 9pt</div>  -->
        <!-- <div class="text-danger ps-3" style="font-family: Courier; font-size: 12px">Courier, 12px</div>  -->
    </Vue3DraggableResizable>
  </template>
  
  <script>
  import { ref, watch, onMounted } from 'vue'
  import Vue3DraggableResizable from 'vue3-draggable-resizable'
  // import Datepicker from 'vue3-date-time-picker'
  import 'vue3-date-time-picker/dist/main.css'
  import Modal from '@/components/Modal.vue'
  
  export default {
    props: [ 'data', 'boxClass', 'signee', 'isDragable', 'scale', 'showAttr'],
    components: { Vue3DraggableResizable, /* Datepicker, */ Modal },
    emits: ['getPosition', 'delBox', 'getResize'],
    setup (props, { emit }) {
  
        const x = ref(props.data.position.x)
        const y = ref(props.data.position.y)
  
        const w = ref(280)  // ref(props.data.w)
        const h = ref(38)   // ref(props.data.h)
        const w2 = ref(280) // ui
        const h2 = ref(38)  // ui
        const signee = ref(props.signee)
        const label = ref(props.data.label)
        const placeHolder = ref(props.data.placeholder)
        const mandatory = ref(props.data.isMandatory)
        const maxChar = ref(props.data.maxChar)
        const page = ref(props.data.position.page)
        const objType = ref(props.data.type)
        const fontFamily = ref(props.data.format.font)
        const fontSize = ref(props.data.format.size)
        const isBold = ref(props.data.format.bold)
        const isItalic = ref(props.data.format.italic)
        const fontWeight = ref('normal') // normal/bold
        const fontStyle = ref('normal')  // normal/italic
        const boxClass = ref(props.boxClass)
        const scale = ref(props.scale)
        const excPages = ref(props.data.excludedPages)
        const showAttr = ref(props.showAttr)

        const comboFullLegalName    = ref(props.data.type === 'comboSignature' && props.data.combo.includes('cFullLegalName') ? true : false)
        const comboDesignation      = ref(props.data.type === 'comboSignature' && props.data.combo.includes('cDesignation') ? true : false)
        const comboOrganization     = ref(props.data.type === 'comboSignature' && props.data.combo.includes('cOrganization') ? true : false)
        const comboNRIC             = ref(props.data.type === 'comboSignature' && props.data.combo.includes('cNric') ? true : false)
        const comboPassport         = ref(props.data.type === 'comboSignature' && props.data.combo.includes('cPassport') ? true : false)
        const comboDateSigned       = ref(props.data.type === 'comboSignature' && props.data.combo.includes('cDateSigned') ? true : false)
  
        const getPosition = ref(0)
        const id = ref(props.data.id)
        const editable = ref([])
  
        const date = ref(label.value)
        const mdlBoxId = ref(null)
  
        if (objType.value === 'fullSignature') {
            w.value = 250
            h.value = 120
        }
  
        if (objType.value === 'initial') {
            w.value = 140
            h.value = 60
        }
  
        if (objType.value === 'stamp') {
            w.value = 250
            h.value = 140
        }
  
        if (objType.value === 'comboSignature') {
            w.value = 250
            h.value = 240
        }
  
        if (objType.value === 'textarea') {
            w.value = props.data.width
            h.value = props.data.height
        }
        
        if (fontFamily.value === undefined) {
            fontFamily.value = 'Helvetica'
  
        } else if(fontFamily.value.includes('Courier')) {
            fontFamily.value = 'Courier'
  
        } else if(fontFamily.value.includes('Helvetica')) {
            fontFamily.value = 'Helvetica'
            
        } else if(fontFamily.value.includes('TimesRoman')) {
            fontFamily.value = 'TimesRoman'
        }
  
        if (fontSize.value === undefined) {
            fontSize.value = 10
        }
  
        if (isBold.value === true || isBold.value === 'true') {
            fontWeight.value = 'bold'
        } else {
            fontWeight.value = 'normal'
        }
  
        if (isItalic.value === true || isItalic.value === 'true') {
            fontStyle.value = 'italic'
        } else {
            fontStyle.value = 'normal'
        }
       
        watch([x, y, label, placeHolder, fontFamily, fontSize, isBold, isItalic, mandatory, maxChar, w, h, excPages, comboFullLegalName, comboNRIC, comboPassport, comboDesignation, comboOrganization, comboDateSigned], () => {
            // console.info('***** DRAGBOX - WATCH', x.value, y.value, 'showAttr:', showAttr.value)
            // console.info('WATCH dragbox ('+ scale.value +')', w.value, h.value, '[', x.value, y.value, ']', parseInt(x.value/scale.value))
            // ORIG  emit('getPosition', id.value, x.value, y.value, signee.value, props.data.label, props.data.placeholder, props.data.isMandatory, props.data.maxChar, page.value, objType.value, props.data.format.font, props.data.format.size, w.value, h.value, props.data.excludedPages, comboFullLegalName.value, comboNRIC.value, comboPassport.value, comboDesignation.value, comboOrganization.value, comboDateSigned.value)
            // console.info('getpos watch', showAttr.value)
            emit('getPosition', showAttr.value, id.value, (scale.value === 1 ? x.value : parseInt(x.value/scale.value)), (scale.value === 1 ? y.value : parseInt(y.value/scale.value)), signee.value, props.data.label, props.data.placeholder, props.data.isMandatory, props.data.maxChar, page.value, objType.value, props.data.format.font, fontSize.value, isBold.value, isItalic.value, w.value, h.value, props.data.excludedPages, comboFullLegalName.value, comboNRIC.value, comboPassport.value, comboDesignation.value, comboOrganization.value, comboDateSigned.value)            
        })

        const toggleBold = () => {
            isBold.value = !isBold.value
            if (isBold.value === true || isBold.value === 'true') {
                fontWeight.value = 'bold'
            } else {
                fontWeight.value = 'normal'
            }
        }
  
        const toggleItalic = () => {
            isItalic.value = !isItalic.value
            if (isItalic.value === true || isItalic.value === 'true') {
                fontStyle.value = 'italic'
            } else {
                fontStyle.value = 'normal'
            }
        }
  
        const changeFont = (opt) => {
            if (opt === '+') {
                if (fontSize.value < 18) {
                    fontSize.value = fontSize.value + 2
                }
            } else {
                if (fontSize.value > 8) {
                    fontSize.value = fontSize.value - 2
                }
            }
        }
  
        const resizeEndHandle = async (obj) => {
            emit('getResize', signee.value, objType.value, id.value, obj.w, obj.h)
        }
  
        const toggleEdit = (id) => {
            editable.value[id] = !(editable.value[id] === true ? true : false)
        }
  
        const delBox = (id) => {
            emit('del', id)
        }
  
        const openAttr = () => {
            showAttr.value = true
            // console.info('open attr')
            // emit('getBox', id.value, x.value, y.value, signee.value, props.data.label, props.data.placeholder, props.data.isMandatory, props.data.maxChar, page.value, objType.value, props.data.format.font, props.data.format.size, props.data.format.bold, props.data.format.italic, props.data.excludedPages, comboFullLegalName.value, comboNRIC.value, comboPassport.value, comboDesignation.value, comboOrganization.value, comboDateSigned.value)
            emit('getPosition', true, id.value, (scale.value === 1 ? x.value : parseInt(x.value/scale.value)), (scale.value === 1 ? y.value : parseInt(y.value/scale.value)), signee.value, props.data.label, props.data.placeholder, props.data.isMandatory, props.data.maxChar, page.value, objType.value, props.data.format.font, fontSize.value, isBold.value, isItalic.value, w.value, h.value, props.data.excludedPages, comboFullLegalName.value, comboNRIC.value, comboPassport.value, comboDesignation.value, comboOrganization.value, comboDateSigned.value)
        }
  
        const setDate = (value) => {
            date.value = value
            label.value = value
        }
  
        onMounted(() => {
            w2.value = w.value * scale.value
            h2.value = h.value * scale.value

            x.value = x.value * scale.value
            y.value = y.value * scale.value

            // console.info('**** DRAGBOX: onmounted', id.value, 'showAttr', showAttr.value)
        })
  
        return { 
          x, y, w, h, id, signee, label, placeHolder, mandatory, maxChar, excPages, page, objType, fontFamily, fontSize, fontWeight, fontStyle,
          editable, date, boxClass, getPosition, toggleEdit, delBox, setDate, openAttr, mdlBoxId,
          comboFullLegalName, comboNRIC, comboPassport, comboDesignation, comboOrganization, comboDateSigned, resizeEndHandle, 
          changeFont, toggleBold, toggleItalic, isBold, isItalic, scale,
          w2, h2, showAttr
        }
    }
  }
  </script>
  
  <style>
  .divFont {
      position: relative; 
      opacity: 0.9;
      top: 3px;
      left: 10px;
      padding: 3px 10px 5px 10px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      width: 185px;
      height: 35px;
  }
  </style>