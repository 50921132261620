<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)" />
    <TopNavigation step="3" :desc="objLSProj.name" :descOwner="projOwner" :descEntity="projEntity" />

    <!-- <div style="height: 100px; background-color: yellow"><br></div> -->
    <div class="contentDiv2">
        <div class="row">
            <span v-if="!showToolbox" class="toolboxBtnClose" @click="toggleToolbox">
                <fa icon="chevron-right" class="text-white" />
            </span>
            <span v-if="showToolbox" class="toolboxBtnOpen" @click="toggleToolbox">
                <fa icon="chevron-left" class="text-white" style="padding-left: 2px;" />
            </span>

            <!-- Toolbox -->
            <div v-if="showToolbox === true" class="col-5 col-md-3 col-lg-2 bottom-0 p-0 vh-100 toolbarFloat" style="z-index: 3; border: 1px solid var(--table-border-color)">
                <table class="table table-borderless table-sm" border="0">
                    <tbody class="px-3">
                        <tr>
                            <td colspan="2" class="pt-2 small">

                                <!-- Pagination -->
                                <div>
                                    <table align="center" border="0" width="100%">
                                        <tr v-if="editPage === false">
                                            <td v-if="page === 1" class="pe-2 text-secondary"><fa icon="angle-double-left" :disabled="page === 1" /></td>
                                            <td v-if="page !== 1" class="pe-2 isLink" @click="navigatePage('first'); updPageNum(page)"><fa icon="angle-double-left" /></td>
                                            <td v-if="page <= 1" class="px-2 text-secondary"><fa icon="angle-left" /></td>
                                            <td v-if="page > 1" class="px-2 isLink" @click="navigatePage('prev'); updPageNum(page)"><fa icon="angle-left" /></td>
                                            <td><span class="isLinkPrimary" @click="editPage = true">{{ goToPage.toLocaleString() }}</span> / {{ pageCount.toLocaleString() }}</td>
                                            <td v-if="page >= pageCount" class="px-2 text-secondary"><fa icon="angle-right" /></td>
                                            <td v-if="page < pageCount" class="px-2 isLink" @click="navigatePage('next'); updPageNum(page)"><fa icon="angle-right" /></td>
                                            <td v-if="page === pageCount" class="ps-2 text-secondary"><fa icon="angle-double-right" /></td>
                                            <td v-if="page !== pageCount" class="ps-2 isLink" @click="navigatePage('last'); updPageNum(page)"><fa icon="angle-double-right" /></td>
                                        </tr>
                                        <tr v-else>
                                            <td>
                                                <div class="d-flex justify-content-center">
                                                    <span class="input-group input-group-xs" style="width: 150px">
                                                        <span class="input-group-text">Go to page</span>
                                                        <input type="text" v-model="goToPage" autofocus class="form-control form-control-xs" @input="navigatePage(); updPageNum(page)" @focusout="editPage = false" >
                                                    </span>
                                                    <span class="ps-2 pt-1">/ {{ pageCount.toLocaleString() }}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="pt-0 pb-0 small">
                                <table align="center">
                                    <tr>
                                        <td v-if="Math.floor(scale*100) === 0" width="50px" class="px-2 text-end"><fa icon="minus" /></td>
                                        <td v-else width="50px" class="px-2 text-end isLinkPrimary" @click="zoomOut"><fa icon="minus" /></td>
                                        <td v-if="scale === 0.8" width="70px" class="pe-2 text-center" @click="zoomDefault">{{Math.floor(scale*100)}}%</td>
                                        <td v-else width="70px" class="pe-2 text-center isLinkPrimary" @click="zoomDefault">{{Math.floor(scale*100)}}%</td>
                                        <td v-if="Math.floor(scale*100) === 200" width="50px" class="ps-2 pe-1"><fa icon="plus" /></td>
                                        <td v-else width="50px" class="ps-2 isLinkPrimary pe-1" @click="zoomIn"><fa icon="plus" /></td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr v-if="doc !== null">
                            <td colspan="2">
                                <Step number="1" desc="Select document" :desc2="docName.replace('.pdf', '')" :totalDoc="docs.length" @click="showStep1Box = !showStep1Box" :expandContent="showStep1Box" :key="Math.random()" />
                                <div v-if="showStep1Box === true" class="ms-4 me-2 pb-2">
                                    <span class="isLink text-nowrap">
                                        <a href="#" v-if="docs.length > 1" data-bs-toggle="modal" data-bs-target="#mdlDoc">
                                            <div class="isLink" :class="signeeSelectedClass + 'Bg'" style="padding: 0.2rem 0.5rem; border-radius: 0.2rem; font-size: 12.25px;">
                                                <DocStatus :status="docStatus" :shred="docShred" class="" />
                                                {{ (docName.replace('.pdf', '').length > 22 ? docName.substring(0, 22) + '...' : docName.replace('.pdf', '')) }}
                                            </div>
                                        </a>
                                        <div v-else class="isTooltipsLabel" style="padding: 0.2rem 0.5rem; border-radius: 0.2rem; font-size: 12.25px;">222
                                            <DocStatus :status="docStatus" :shred="docShred" />
                                            {{ (docName.replace('.pdf', '').length > 22 ? docName.substring(0, 22) + '...' : docName.replace('.pdf', '')) }}
                                        </div>
                                    </span>
                                </div>

                            </td>
                        </tr>
                        <tr><td><hr style="margin-top: 5px; margin-bottom: 0px;" /></td></tr>
                        <tr v-if="doc !== null">
                            <td colspan="2" class="pt-0">
                                <Step number="2" desc="Select signee" :desc2="signeeName" :totalSignee="totalDocSignee" @click="showStep2Box = !showStep2Box" :expandContent="showStep2Box" :key="Math.random()"/>
                                <div v-if="showStep2Box === true" class="ms-4 me-2 pb-2">
                                    <a v-if="signeeOpt === 'signee'" href="#" data-bs-toggle="modal" data-bs-target="#mdlSignee">
                                        <div v-if="signeeName !== null" class="isLink" :class="signeeSelectedClass + 'Bg'" style="padding: 0.2rem 0.5rem; border-radius: 0.2rem; font-size: 12.25px;">
                                            <fa icon="circle-user" size="lg" class="text-grey isLabel" />
                                            {{ (signeeName.length > 22 ? signeeName.substring(0, 22) + '...' : signeeName) }}
                                        </div>
                                    </a>
                                    <a v-else href="#" data-bs-toggle="modal" data-bs-target="#mdlSignee">
                                        <span v-if="workflowTitle !== null" class="isLink" :class="signeeSelectedClass + 'Bg'" style="padding: 0.2rem 0.5rem; border-radius: 0.2rem; font-size: 12.25px;">
                                            <fa icon="sitemap" size="lg" class="text-grey isLabel" />
                                            {{ (workflowTitle.length > 22 ? workflowTitle.substring(0, 22) + '...' : workflowTitle) }}
                                        </span>
                                        <div style="margin-top: 10px">
                                            <span :class="signeeSelectedClass + 'Bg'" style="padding: 0.2rem 0.5rem; border-radius: 0.2rem">
                                                <span class="me-2">STEP {{workflowSigneeStep}}</span>
                                                <span v-if="workflowSigneeIsRole" class="badge bg-grey">{{workflowSignee}}</span>
                                                <span v-else>{{workflowSignee}}</span>
                                            </span>
                                        </div>
                                    </a>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="docShred === 'NO' && docStatus === 'DRAFT'"><td><hr style="margin-top: 5px; margin-bottom: 0px;" /></td></tr>
                        <tr>
                            <td v-if="docShred === 'PENDING'" colspan="2" class="pt-0" style="padding-left: 12px; padding-right: 12px">
                                <div class="mt-2 p-2 border shadow-sm" style="border-radius: 5px">
                                    <div><fa icon="lightbulb" size="lg" /> Tips</div>
                                    <div class="lblTiny">This document is pending for other signees approval to shred.</div>
                                </div>
                            </td>
                            <td v-else-if="docStatus !== 'DRAFT'" colspan="2" class="pt-0 px-2 small">
                                <div class="mt-2 p-2 border shadow-sm" style="border-radius: 5px">
                                    <div><fa icon="lightbulb" size="lg" /> Tips</div>
                                    <div class="lblTiny">This document is not editable because already sent out.</div>
                                    <div class="lblTiny">You may revise it if insists to make changes on it.</div>
                                    <div class="d-grid">
                                        <button @click="reviseDoc" type="button" class="btn btn-sm btn-outline-secondary">
                                            <fa icon="rotate-right" size="lg" class="me-2" />Revise
                                        </button>
                                    </div>
                                </div>
                            </td>
                            <td v-else colspan="2" class="pt-0">
                                <Step number="3" desc="Setup field" desc2="Setup field" @click="showStep3Box = !showStep3Box" :expandContent="showStep3Box" :key="Math.random()" />
                                <div v-if="showStep3Box === true" class="ms-4 me-2 mb-2 small">
                                    <div class="ps-3">
                                        <div class="py-1">
                                            <span class="isLink" :class="signeeSelectedClass" @click="add('fullSignature', winTop)">
                                                <fa icon="signature" class="me-2" />Full Signature
                                            </span>
                                            <span class="float-end">
                                                <span v-if="getFieldPage('fullSignature') !== []" class="badge bg-secondary text-white me-1 isLink" v-for="p in getFieldPage('fullSignature')" :key="p" @click="navigatePage(p); updPageNum(p)">
                                                    <!-- <Popper class="popperDark" arrow hover content="Page">{{ p }}</Popper> -->
                                                    {{ p }}
                                                </span>
                                            </span>
                                        </div>
                                        <div class="py-1">
                                            <span class="isLink" :class="signeeSelectedClass" @click="add('initial', winTop)">
                                                <span :class="showAttr === true && box.type === 'initial' ? 'fw-bold' : ''">
                                                    <fa icon="pen-nib" class="me-2" />Initial
                                                </span>
                                            </span>
                                            <span class="float-end">
                                                <span v-if="getFieldPage('initial') !== []" class="badge bg-secondary text-white me-1 isLink" v-for="p in getFieldPage('initial')" :key="p" @click="navigatePage(p); updPageNum(p)">
                                                    <!-- <Popper class="popperDark" arrow hover content="Page">{{ p }}</Popper> -->
                                                    {{ p }}
                                                </span>
                                            </span>
                                        </div>
                                        <div class="py-1">
                                            <span class="isLink" :class="signeeSelectedClass" @click="add('stamp', winTop)">
                                                <fa icon="stamp" class="me-2" />Stamp
                                            </span>
                                            <span class="float-end">
                                                <span v-if="getFieldPage('stamp') !== []" class="badge bg-secondary text-white me-1 isLink" v-for="p in getFieldPage('stamp')" :key="p" @click="navigatePage(p); updPageNum(p)">
                                                    <!-- <Popper class="popperDark" arrow hover content="Page">{{ p }}</Popper> -->
                                                    {{ p }}
                                                </span>
                                            </span>
                                        </div>
                                        <div class="py-1">
                                            <span class="isLink" :class="signeeSelectedClass" @click="add('comboSignature', winTop)">
                                                <fa icon="object-group" class="me-2" />Combo Signature
                                            </span>
                                            <span class="float-end">
                                                <span v-if="getFieldPage('comboSignature') !== []" class="badge bg-secondary text-white me-1 isLink" v-for="p in getFieldPage('comboSignature')" :key="p" @click="navigatePage(p); updPageNum(p)">
                                                    <!-- <Popper class="popperDark" arrow hover content="Page">{{ p }}</Popper> -->
                                                    {{ p }}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showStep3Box === true" class="text-secondary small text-rotate" style="padding-left: 55px;">Signature</div>

                                <div v-if="showStep3Box === true" class="ms-4 me-2 mb-2 small">
                                    <div class="ps-3">
                                        <div class="py-1">
                                            <span class="isLink" :class="signeeSelectedClass" @click="add('dateSigned', winTop)">
                                                <span :class="showAttr === true && box.type === 'dateSigned' ? 'fw-bold' : ''">
                                                    <fa icon="calendar-alt" class="me-2" />Date Signed
                                                </span>
                                            </span>
                                            <span class="float-end">
                                                <span v-if="getFieldPage('dateSigned') !== []" class="badge bg-secondary text-white me-1 isLink" v-for="p in getFieldPage('dateSigned')" :key="p" @click="navigatePage(p); updPageNum(p)">
                                                    <!-- <Popper class="popperDark" arrow hover content="Page">{{ p }}</Popper> -->
                                                    {{ p }}
                                                </span>
                                            </span>
                                        </div>
                                        <div class="py-1">
                                            <span class="isLink" :class="signeeSelectedClass" @click="add('name', winTop)">
                                                <span :class="showAttr === true && box.type === 'name' ? 'fw-bold' : ''">
                                                    <fa icon="user-circle" class="me-2" />Name
                                                </span>
                                            </span>
                                            <span class="float-end">
                                                <span v-if="getFieldPage('name') !== []" class="badge bg-secondary text-white me-1 isLink" v-for="p in getFieldPage('name')" :key="p" @click="navigatePage(p); updPageNum(p)">
                                                    <!-- <Popper class="popperDark" arrow hover content="Page">{{ p }}</Popper> -->
                                                    {{ p }}
                                                </span>
                                            </span>
                                        </div>
                                        <div class="py-1">
                                            <span class="isLink" :class="signeeSelectedClass" @click="add('email', winTop)">
                                                <span :class="showAttr === true && box.type === 'email' ? 'fw-bold' : ''">
                                                    <fa icon="at" class="me-2" />Email
                                                </span>
                                            </span>
                                            <span class="float-end">
                                                <span v-if="getFieldPage('email') !== []" class="badge bg-secondary text-white me-1 isLink" v-for="p in getFieldPage('email')" :key="p" @click="navigatePage(p); updPageNum(p)">
                                                    <!-- <Popper class="popperDark" arrow hover content="Page">{{ p }}</Popper> -->
                                                    {{ p }}
                                                </span>
                                            </span>
                                        </div>
                                        <div class="py-1">
                                            <span class="isLink" :class="signeeSelectedClass" @click="add('idPassport', winTop)">
                                                <span :class="showAttr === true && box.type === 'idPassport' ? 'fw-bold' : ''">
                                                    <fa icon="id-card" class="me-2" />ID/Passport
                                                </span>
                                            </span>
                                            <span class="float-end">
                                                <span v-if="getFieldPage('idPassport') !== []" class="badge bg-secondary me-1 isLink" v-for="p in getFieldPage('idPassport')" :key="p" @click="navigatePage(p); updPageNum(p)">
                                                    {{ p }}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showStep3Box === true" class="text-secondary small text-rotate" style="padding-left: 58px;">Auto-fill</div>

                                <div v-if="showStep3Box === true" class="ms-4 me-2 mb-0 small pb-1">
                                    <div class="ps-3">
                                        <div class="py-1">
                                            <span class="isLink" :class="signeeSelectedClass" @click="add('textbox', winTop)">
                                                <span :class="showAttr === true && box.type === 'textbox' ? 'fw-bold' : ''">
                                                    <fa icon="font" class="me-2" />Textbox
                                                </span>
                                            </span>
                                            <span class="float-end">
                                                <span v-if="getFieldPage('textbox') !== []" class="badge bg-secondary text-white me-1 isLink" v-for="p in getFieldPage('textbox')" :key="p" @click="navigatePage(p); updPageNum(p)">
                                                    <!-- <Popper class="popperDark" arrow hover content="Page">{{ p }}</Popper> -->
                                                    {{ p }}
                                                </span>
                                            </span>
                                        </div>
                                        <div class="py-1">
                                            <span class="isLink" :class="signeeSelectedClass" @click="add('textarea', winTop)">
                                                <span :class="showAttr === true && box.type === 'textarea' ? 'fw-bold' : ''">
                                                    <fa icon="bars" class="me-2" />Textarea
                                                </span>
                                            </span>
                                            <span class="float-end">
                                                <span v-if="getFieldPage('textarea') !== []" class="badge bg-secondary text-white me-1 isLink" v-for="p in getFieldPage('textarea')" :key="p" @click="navigatePage(p); updPageNum(p)">
                                                    <!-- <Popper class="popperDark" arrow hover content="Page">{{ p }}</Popper> -->
                                                    {{ p }}
                                                </span>
                                            </span>
                                        </div>
                                        <div class="py-1">
                                            <span class="isLink" :class="signeeSelectedClass" @click="add('checkbox', winTop)">
                                                <span :class="showAttr === true && box.type === 'checkbox' ? 'fw-bold' : ''">
                                                    <fa icon="check-square" class="me-2" />Checkbox
                                                </span>
                                            </span>
                                            <span class="float-end">
                                                <span v-if="getFieldPage('checkbox') !== []" class="badge bg-secondary text-white me-1 isLink" v-for="p in getFieldPage('checkbox')" :key="p" @click="navigatePage(p); updPageNum(p)">
                                                    <!-- <Popper class="popperDark" arrow hover content="Page">{{ p }}</Popper> -->
                                                    {{ p }}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="showStep3Box === true" class="text-secondary small text-rotate" style="padding-left: 40px;">Standard</div>

                                <!-- Attribute start -->
                                 <div v-if="showAttr" class="ms-4 me-2 mt-0 pt-0">
                                    <div><hr style="margin-top: 12px" /></div>
                                    <div v-if="showStep3Box === false && box" class=" mb-2 small fw-bold" :class="signeeSelectedClass">
                                        <span v-if="box && box.type === 'initial'">
                                            <fa icon="pen-nib" class="me-2" />Initial
                                        </span>
                                        <span v-if="box && box.type === 'dateSigned'">
                                            <fa icon="calendar-alt" class="me-2" />Date Signed
                                        </span>
                                        <span v-if="box && box.type === 'name'">
                                            <fa icon="user-circle" class="me-2" />Name
                                        </span>
                                        <span v-if="box && box.type === 'email'">
                                            <fa icon="at" class="me-2" />Email
                                        </span>
                                        <span v-if="box && box.type === 'isPassport'">
                                            <fa icon="id-card" class="me-2" />ID/Passport
                                        </span>
                                        <span v-if="box && box.type === 'textbox'">
                                            <fa icon="font" class="me-2" />Textbox
                                        </span>
                                        <span v-if="box && box.type === 'textarea'">
                                            <fa icon="bars" class="me-2" />Textarea
                                        </span>
                                        <span v-if="box && box.type === 'checkbox'">
                                            <fa icon="check-square" class="me-2" />Checkbox
                                        </span>
                                    </div>
                                    <div v-if="box && box.type === 'initial'">
                                        <div class="mb-2">
                                            <span class="mb-2 small" :class="signeeSelectedClass">Appear in all pages</span> 
                                            <span class="px-1 isLink float-end" :class="signeeSelectedClass" data-bs-toggle="modal" data-bs-target="#mdlShowJson" @click="viewJson">
                                                <Popper class="popperDark" arrow hover content="API Params">
                                                    <fa icon="code" />
                                                </Popper>
                                            </span>
                                        </div>
                                        <div class="input-group input-group-xs mb-2">
                                            <span class="input-group-text" :class="signeeSelectedClass">Exclude</span>
                                            <input type="text" v-model="excludedPages" class="form-control form-control-xs" placeholder="Excluded pages i.e. 1, 5 (Optional)" :class="signeeSelectedClass" />
                                        </div>  
                                    </div>
                                    <div v-if="box && box.type !== 'initial'">
                                        <div class="mb-2">
                                            <table border="0" width="100%">
                                                <tr>
                                                    <td>
                                                        <div class="form-check form-switch" :class="signeeSelectedClass">
                                                            <input class="form-check-input" type="checkbox" v-model="mandatory" :class="signeeSelectedClass" :disabled="docStatus !== 'DRAFT'">
                                                            <label class="ms-2 small">Mandatory</label>
                                                        </div>
                                                    </td>
                                                    <td width="60px">
                                                        <span class="pt-0 px-1 isLink float-end" :class="signeeSelectedClass" data-bs-toggle="modal" data-bs-target="#mdlShowJson" @click="viewJson">
                                                            <Popper class="popperDark" arrow hover content="API Params">
                                                                <fa icon="code" />
                                                            </Popper>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        
                                        <div class="mb-2">
                                            <select ref="lstFontFamily" v-model="fontFamily" @change="changeFontFamily()" class="form-select form-select-xs" :class="signeeSelectedClass" :disabled="docStatus !== 'DRAFT'">
                                                <option value="Courier">Courier</option>
                                                <!-- <option value="CourierBold">CourierBold</option>
                                                <option value="CourierOblique">CourierOblique</option>
                                                <option value="CourierBoldOblique">CourierBoldOblique</option> -->
                                                <option value="Helvetica">Helvetica</option>
                                                <!-- <option value="HelveticaBold">HelveticaBold</option>
                                                <option value="HelveticaOblique">HelveticaOblique</option>
                                                <option value="HelveticaBoldOblique">HelveticaBoldOblique</option> -->
                                                <option value="TimesRoman">TimesRoman</option>
                                                <!-- <option value="TimesRomanBold">TimesRomanBold</option>
                                                <option value="TimesRomanItalic">TimesRomanItalic</option>
                                                <option value="TimesRomanBoldItalic">TimesRomanBoldItalic</option> -->
                                            </select>
                                        </div>

                                        <div class="mb-2">
                                            <button type="button" @click ="setBold" class="btn btn-xs me-1" :class="isBold === true ? 'bg-secondary text-white' : signeeSelectedClass" style="width: 25px">B</button>
                                            <button type="button" @click="setItalic" class="btn btn-xs" :class="isItalic === true ? 'bg-secondary text-white' : signeeSelectedClass" style="width: 25px">I</button>
                                        </div>

                                        <div v-if="box && (box.type === 'textbox' || box.type === 'textarea')" class="mb-2">
                                            <table width="100%" border="0">
                                                <tr>
                                                    <td width="55%" class="p-0">
                                                        <div class="input-group input-group-xs">
                                                            <span class="input-group-text bg-secondary text-white" :class="signeeSelectedClass">Size</span>
                                                            <select v-model="fontSize" @change="changeFontSize()" class="form-select form-select-xs" :class="signeeSelectedClass" :disabled="docStatus !== 'DRAFT'">
                                                                <option :value="n" v-for="n in fontSizeRange" :key="n">{{n}}</option>
                                                            </select>
                                                        </div>
                                                    </td>
                                                    <td class="px-1">
                                                        <input type="textbox" v-model="maxChar" class="form-control form-control-xs" placeholder="Max char" :class="signeeSelectedClass" :disabled="docStatus !== 'DRAFT'"/>
                                                    </td>
                                                    <td>
                                                        <!-- <Popper class="popperDark" arrow hover content="Maximum character or length of the field. Limit to 50 characters for textbox and 250 characters for textarea."> -->
                                                        <Popper class="popperDark" arrow hover content="Maximum 50 characters for textbox and 250 characters for textarea.">
                                                            <fa icon="circle-question" />
                                                        </Popper>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>

                                        <div v-else class="mb-2">
                                            <div class="input-group input-group-xs">
                                                <span class="input-group-text bg-secondary text-white" :class="signeeSelectedClass">Size</span>
                                                <select v-model="fontSize" @change="changeFontSize()" class="form-select form-select-xs" :class="signeeSelectedClass" :disabled="docStatus !== 'DRAFT'">
                                                    <option :value="n" v-for="n in fontSizeRange" :key="n">{{n}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        
                                        <div v-if="box && (box.type === 'textbox' || box.type === 'textarea' || box.type === 'checkbox')" class="mb-2">
                                            <div class="input-group input-group-xs">
                                                <span class="input-group-text bg-secondary text-white" :class="signeeSelectedClass">Name</span>
                                                <input type="textbox" v-model="fontLabel" class="form-control form-control-xs" placeholder="Name" :class="signeeSelectedClass" :disabled="docStatus !== 'DRAFT'" @input="refreshBox" @focus="selectText" />
                                            </div>
                                            
                                        </div>
                                        <div v-if="box && (box.type === 'textbox' || box.type === 'textarea' || box.type === 'checkbox')" class="mb-2">
                                            <input type="textbox" v-model="fontPlaceholder" class="form-control form-control-xs" placeholder="Description" :class="signeeSelectedClass" :disabled="docStatus !== 'DRAFT'"/>
                                        </div>
                                        <!-- <div>
                                            <div class="input-group input-group-xs">
                                                <input type="text" maxlength="2" class="form-control form-control-xs" :class="signeeSelectedClass" >
                                                <input type="text" aria-label="Last name" class="form-control form-control-xs" :class="signeeSelectedClass">
                                                <span class="input-group-text" :class="signeeSelectedClass">
                                                    <Popper class="popperDark" arrow hover content="Max char and field name"><fa icon="exclamation" /></Popper>
                                                </span>
                                            </div>
                                        </div> -->
                                    </div>
                                 </div>
                                <!-- Attribute end -->
                            </td>
                        </tr>
                        <tr v-if="docShred === 'NO' && docStatus === 'DRAFT'"><td><hr style="margin-top: 5px; margin-bottom: 0px;" /></td></tr>
                        <tr v-if="docShred === 'NO' && docStatus === 'DRAFT'">
                            <td colspan="2" class="pt-0 pb-3">
                                <Step number="4" desc="Save changes" desc2="Save changes" @click="showStep4Box = !showStep4Box" :expandContent="showStep4Box" :key="Math.random()" />
                                <div class="ps-4 pe-2">
                                    <div v-if="showStep4Box === true" class="btn-group" style="width: 100%">
                                        <button v-if="docStatus === 'DRAFT'" @click="saveDoc" type="button" class="btn btn-sm" :class="dbBoxes !== null && boxes !== dbBoxes ? 'btn-info text-white' : 'btn-outline-secondary'">
                                            <fa icon="floppy-disk" size="lg" class="me-2" />Save
                                        </button>
                                        <button v-else type="button" class="btn btn-sm btn-secondary" disabled>
                                            <fa icon="floppy-disk" size="lg" class="me-2" />Save
                                        </button>
                                        <button v-if="showReview === true && boxes === dbBoxes" @click="$router.push('/reviewInvite')" type="button" class="btn btn-sm" :class="(dbBoxes !== null && boxes !== dbBoxes || boxes.length === 0) ? 'btn-outline-secondary' : 'btn-info text-white'">
                                            <fa icon="envelope-open" size="lg" class="me-2"/>Review
                                        </button>
                                        <button v-if="showReview === true && boxes !== dbBoxes" data-bs-toggle="modal" data-bs-target="#mdlReview" type="button" class="btn btn-sm" :class="(dbBoxes !== null && boxes !== dbBoxes || boxes.length === 0) ? 'btn-outline-secondary' : 'btn-info text-white'">
                                            <fa icon="envelope-open" size="lg" class="me-2"/>Review
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div><!-- Toolbox End-->

            <div v-if="proj.length > 0 && proj.status !== 'WIP'" class="mainBox mainBox55 shadow-sm border">
                <div class="row">
                    <div class="col-5 col-lg-4 mb-2 text-center">
                        <fa icon="triangle-exclamation" style="font-size: 120px"/>
                    </div>
                    <div class="col-7 col-lg-8 mb-2">
                        <div class="fw-bold mb-3" style="font-size: 20px">Hmmm... We're sorry, but you don't have permission to view this page.</div>
                        <div>Please email to <a href="mailto:wecare@signon.my" ><u>wecare@signon.my</u></a> for more information.</div>
                        <div>Or you may search <span class="text-primary isLink" @click="$router.push('/project')">folder</span> from the rack again.</div>
                    </div>
                    <div class="col-12"><hr class="my-3"/></div>
                    <div class="col-12 text-center">
                        <button class="btn btn-secondary" @click="$router.push({ path: '/project' })">
                            <fa icon="folder" size="lg" class="me-2" />Folder
                        </button>
                    </div>
                </div>
            </div>

            <div v-if="proj.status === 'WIP' && (objLSProj.id === '' || objLSProj.id === null || objLSProj.id === undefined)" class="mainBox mainBox55 shadow-sm border">
                <div class="row">
                    <div class="col-5 col-lg-4 mb-2 text-center">
                        <fa icon="triangle-exclamation" style="font-size: 120px"/>
                    </div>
                    <div class="col-7 col-lg-8 mb-2">
                        <div class="fw-bold mb-3" style="font-size: 20px">Oops... we are unable to locate the folder that you are trying to access. It might have been relocated to a different rack.</div>
                        <div>Please contact the editor(s) for more information.</div>
                        <div>Or you may search <span class="text-primary isLink" @click="$router.push('/project')">folder</span> from the rack again.</div>
                    </div>
                    <div class="col-12"><hr class="my-3"/></div>
                    <div class="col-12 text-center">
                        <button class="btn btn-secondary" @click="$router.push({ path: '/project' })">
                            <fa icon="folder" size="lg" class="me-2" />Folder
                        </button>
                    </div>
                </div>
            </div>

            <div v-else :class="contentClass">
                <!-- <div class="vh-100 gx-0" style="margin-top: 45px; padding-bottom: 120px;"> -->
                <div class="vh-100 gx-0" style="padding-bottom: 30px">
                    <div class="row border-primary" v-if="showDebug">
                        <div class="col-3"></div>
                        <div class="col-9 p-2 bg-light" style="font-size: 10px">

                            <div class="text-warning fw-bold">boxes ({{boxes.length}}) - All signees in doc (all pages)</div>
                            <div class="text-warning ps-2" v-for="b in boxes" :key="b" style="font-size: 10px">
                                - seq: {{b.seq}} | <span class="text-danger">{{b.signeeId}} ({{b.jObject.length}})</span> 
                                <div class="ps-3" v-for="o in b.jObject" :key="o">- {{ o }}</div>
                            </div>
                            
                            <div class="text-success" style="font-size: 10px"><b>docBoxes ({{docBoxes.length}}) - curSignee in doc (all pages)</b> </div>
                            <div class="text-success ps-2" style="font-size: 10px" v-for="b in docBoxes" :key="b">- {{ b }}</div>
                        
                            <div class="text-primary" style="font-size: 10px"><b>pgBoxes ({{pgBoxes.length}}) - curSignee on cur Page</b></div>
                            <div class="text-primary ps-2" v-for="p in pgBoxes" :key="p">- {{p}}</div>
                            <hr />
                            <div>{{typeof box}} box:{{ box }}</div>

                            <!-- <div class="text-secondary fw-bold" style="font-size: 10px">docSignees [{{docSignees.length}}]</div>
                            <div class="text-secondary" style="font-size: 10px" v-for="s in docSignees" :key="s">- {{s.seq}} {{s.signeeId}}</div> -->
                            

                            <!-- <div v-if="boxes !== dbBoxes" class="fw-bold text-warning">NOT matched</div>
                            <div class="text-success ps-2" v-for="b in boxes" :key="b" style="font-size: 10px">
                                <span class="text-danger">{{b.signeeId}} ({{b.jObject.length}})</span> 
                                <div class="ps-3" v-for="o in b.jObject" :key="o">- {{ o }}</div>
                            </div>
                            <div class="text-primary ps-2" v-for="b in dbBoxes" :key="b" style="font-size: 10px">
                                <span class="text-info">{{b.signeeId}} ({{b.jObject.length}})</span> 
                                <div class="ps-3" v-for="o in b.jObject" :key="o">- {{ o }}</div>
                            </div> -->

                            <!-- <div class="text-brown fw-bold" style="font-size: 10px">docs [{{docs.length}}]</div> -->
                            <!-- <div class="text-brown" style="font-size: 10px" v-for="s in docs" :key="s">- {{s}}</div> -->

                            <!-- <div class="text-info" style="font-size: 10px">signees [{{typeof signees}}] ({{signees.length}}) </div> -->
                            
                            <!-- <div class="text-info fw-bold" >signees (Total: {{signees.length}})</div>
                            <div class="text-info ps-2" v-for="(s, idx) in signees" :key="s"> {{ idx }}- {{s.signeeId}} ({{s.totalObj}})</div> -->
                            <!-- <div class="text-info" v-for="(s, idx) in signees" :key="s" style="font-size: 10px">- {{idx}}: [{{s.seq}}] seq | <b>mode: {{s.mode}}</b> |{{s.name}} ( {{s.signeeId}} ), email: {{s.email}}, role:{{ s.role}}</div> -->
                            <!-- <div class="text-info" v-for="s in signees" :key="s" style="font-size: 10px">- {{s}}</div> -->
                        </div>
                    </div>
                    
                    <div v-if="isPdfLoaded === false" class="text-center text-info" style="width: 100%; position: fixed; z-index: 1000; top: 100px; font-size: 14px">
                        <fa icon="spinner" size="lg" class="me-2" />Loading...
                    </div>

                    <!-- Pdf and objects -->
                    <!-- ORIG 
                    <div style="position: relative; width: auto; height: 100%; margin-top: 1px; overflow: auto; top: 0px" class="pdfBg">
                        <div id="pdfBoxContainer" ref="pdfBoxContainer" :style="'width:'+Math.ceil(pdfWidth*scale)+'px; height: '+Math.ceil(pdfHeight*scale)+'px; position: absolute'">
                            <component v-for="b in pgBoxes" :key="randKey" :is="newBox" :data="b" :boxClass="docStatus === 'DRAFT' ? signeeSelectedClass : 'boxDisabled ' + signeeSelectedClass" 
                                :signee="signeeId" :scale="scale" :showAttr="showAttr"
                                @getPosition="getPosition" @del="delBox" @getResize="getResize" :isDragable="docStatus === 'DRAFT'" />
                        </div>
                        <canvas id="pdf-canvas" style="position: absolute; left: 0px;" @click="closeAttr" ></canvas>
                        <div id="pdf-annotation-layer"  style="position: absolute; border: 2px dashed red;"></div>
                    </div> --> 

                    <!-- <div style="position: relative; width: auto; height: 100%; overflow: auto; top: 0px; padding-top: 80px" class="pdfBg"> -->
                    <div style="position: relative; width: auto; height: 100%; overflow: auto; top: 0px; padding-top: 80px" class="pdfBg">
                        <div id="pdfBoxContainer" ref="pdfBoxContainer" :style="'width:'+Math.ceil(pdfWidth*scale)+'px; height: '+Math.ceil(pdfHeight*scale)+'px; position: absolute; right: 0px;'">
                            <component v-for="b in pgBoxes" :key="randKey" :is="newBox" :data="b" :boxClass="docStatus === 'DRAFT' ? signeeSelectedClass : 'boxDisabled ' + signeeSelectedClass" 
                                :signee="signeeId" :scale="scale" :showAttr="showAttr"
                                @getPosition="getPosition" @del="delBox" @getResize="getResize" :isDragable="docStatus === 'DRAFT'" />
                        </div>
                        <canvas v-if="isEncrypted !== true" id="pdf-canvas" style="position: absolute; right: 0px" @click="closeAttr"></canvas>
                        <div v-if="isEncrypted !== true" id="pdf-annotation-layer" style="position: absolute; right: 0px"></div>

                        <div v-if="isEncrypted === true" class="row mt-4">
                            <div class="col-5 col-lg-4 mb-2 text-end">
                                <fa icon="triangle-exclamation" style="font-size: 120px"/>
                            </div>
                            <div class="col-7 col-lg-8 mb-2">
                                <div class="fw-bold mb-3" style="font-size: 20px">Encrypted document</div>
                                <div>Sorry, encrypted PDF is not supported at the moment.</div>
                            </div>
                        </div>
                        
                    </div>
                    <!-- Pdf and objects End -->
                </div>
            </div>

        </div>
        
    </div>

    <!-- Modal: Select Signee --> 
    <div class="modal fade" id="mdlSignee" ref="mdlSignee" tabindex="-1" aria-labelledby="SortSignee" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Select Signee</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                
                <div class="modal-body">
                    <ul v-if="isSignOn === true && isWorkflow" class="nav nav-pills mb-4">
                        <li class="nav-item">
                            <a class="nav-link" :class="signeeOptToggle === 'signee' ? 'active' : ''" @click="signeeOptToggle = 'signee'" data-bs-toggle="pill" href="#signee">Signee</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="signeeOptToggle === 'workflow' ? 'active' : ''" @click="signeeOptToggle = 'workflow'" data-bs-toggle="pill" href="#workflow">Workflow</a>
                        </li>
                    </ul>

                    <div class="tab-content">
                        <div class="tab-pane container" :class="signeeOptToggle === 'signee' ? 'active' : 'fade'" id="signee">
                            <div style="max-height: 400px; overflow-y: auto">
                                <draggable
                                    @change="sortSignee"
                                    tag="ul"
                                    :list="signees"
                                    class="list-group"
                                    handle=".handle"
                                    item-key="signeeId"
                                >
                                    <template #item="{ element, index }">
                                        <li class="list-group-item isLink" :class="signeeClasses[index] + 'Bg'" :style="signeeId === element.signeeId ? 'border-width: 3px' : ''" 
                                            v-if="signeeId !== element.signeeId" @click="selectSignee(element.signeeId, index, element.signeeName, element.signeeId, element)">
                                            <!-- Not selected signee, other signees -->
                                            <table width="100%" border="0">
                                                <tr>
                                                    <td rowspan="2" v-if="inSequence === true" width="5%">
                                                        <span v-if="hasSignee && element.totalObj > 0">
                                                            <fa icon="bars" class="handle me-3" size="lg"/>
                                                        </span>
                                                        <div v-else style="width: 32px"></div>
                                                    </td>
                                                    <td v-if="inSequence === false" rowspan="2" width="5%" ></td>
                                                    <td rowspan="2" width="40px">
                                                        <span>
                                                            <ImgAvatar :isUser="element.isUser" :id="element.jAvatar.userId" displayName="" :isVerified="element.isVerified" :isActive="element.status" :privilege="element.privilege" width="35" height="35" />
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span v-if="inSequence === true" class="badge bg-grey" style="margin-right: 3px">{{index+1}}</span>
                                                        <span class="fw-bold me-2">{{element.signeeName}}</span>
                                                        <span v-if="element.totalObj > 0" class="badge bg-secondary"><fa icon="object-ungroup" class="me-1" />{{ element.totalObj }}</span>
                                                    </td>
                                                    <td width="80px" class="text-end"><span class="badge bg-grey">{{ element.role }}</span></td>
                                                </tr>
                                                <tr>
                                                    <td>{{ element.signeeId }}</td>
                                                </tr>
                                            </table>
                                        </li>

                                        <!-- Selected signee -->
                                        <li v-else class="list-group-item isLabel" :class="signeeClasses[index] + 'Bg'" :style="signeeId === element.signeeId ? 'border-width: 3px' : ''" >
                                            <table width="100%" border="0" >
                                                <tr>
                                                    <td rowspan="2" v-if="inSequence === true" width="5%">
                                                        <span v-if="element.totalObj > 0" >
                                                            <fa icon="bars" class="handle me-3" size="lg" style="cursor: default" />
                                                        </span>
                                                        <div v-else style="width: 32px"></div>
                                                    </td>
                                                    <td v-if="inSequence === false" rowspan="2" width="5%" ></td>
                                                    <td rowspan="2" width="40px">
                                                        <span v-if="!element.isRole">
                                                            <ImgAvatar :isUser="element.isUser" :id="element.jAvatar.userId" displayName="" :isVerified="element.isVerified" :isActive="element.status" :privilege="element.privilege" width="35" height="35" />
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span v-if="inSequence == true" class="badge bg-grey" style="margin-right: 3px">{{index+1}}</span>
                                                        <span class="fw-bold me-2">{{ element.signeeName }}</span>
                                                        <span v-if="element.totalObj > 0" class="badge bg-secondary"><fa icon="object-ungroup" class="me-1" />{{ element.totalObj }}</span>
                                                    </td>
                                                    <td width="80px" class="text-end"><span class="badge bg-grey">{{ element.role }}</span></td>
                                                </tr>
                                                <tr>
                                                    <td> {{ element.signeeId }}</td>
                                                </tr>
                                            </table>
                                        </li>

                                    </template>
                                </draggable>
                            </div>
                        </div>

                        <div class="tab-pane container" :class="signeeOptToggle === 'workflow' ? 'active' : 'fade'" id="workflow">
                            <select v-model="workflowId" class="form-select mb-3">
                                <option value="null">Select workflow</option>
                                <option :value="w.id" v-for="w in arrWorkflow" :key="w.id">{{w.title}}</option>
                            </select> 
                            <div v-if="isWorkflow && workflowId !== 'null'">
                                <div v-for="(w, wIdx) in arrWorkflowSignee" :key="w.signeeId">    
                                    <!-- 1st Item --> 
                                    <div v-if="wIdx === 0" @click="selectWorkflowSignee(wIdx, w.signeeId, w.name, w.isRole)" class="list-group-item isLabel py-2 isLink" :class="signeeClasses[wIdx] + 'Bg'" :style="workflowSigneeStep-1 === wIdx ? 'border-width: 3px' : ''" style="border-top-left-radius: 5px; border-top-right-radius: 5px;">
                                        <span class="me-2">STEP {{wIdx+1}}</span>
                                        <span v-if="w.isRole" class="badge bg-grey">{{w.name}}</span>
                                        <span v-else>{{w.name}}</span>
                                    </div>
                                    <!-- Last Item -->
                                    <div v-else-if="wIdx === (arrWorkflowSignee.length-1)" @click="selectWorkflowSignee(wIdx, w.signeeId, w.name, w.isRole)" class="list-group-item isLabel py-2 isLink" :class="signeeClasses[wIdx] + 'Bg'" :style="workflowSigneeStep-1 === wIdx ? 'border-width: 3px' : ''" style="border-bottom-left-radius: 5px; border-bottom-right-radius: 5px;">
                                        <span class="me-2">STEP {{wIdx+1}}</span>
                                        <span v-if="w.isRole" class="badge bg-grey">{{w.name}}</span>
                                        <span v-else>{{w.name}}</span>
                                    </div>
                                    <!-- Middle Item -->
                                    <div v-else @click="selectWorkflowSignee(wIdx, w.signeeId, w.name, w.isRole)" class="list-group-item isLabel py-2 isLink" :class="signeeClasses[wIdx] + 'Bg'" :style="workflowSigneeStep-1 === wIdx ? 'border-width: 3px' : ''" >
                                        <span>  
                                            <span class="me-2">STEP {{wIdx+1}}</span>
                                            <span v-if="w.isRole" class="badge bg-grey">{{w.name}}</span>
                                            <span v-else>{{w.name}}</span>
                                        </span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> 
                </div>

                <div class="modal-footer">
                    <table border="0" width="100%">
                        <tr>
                            <td>
                                <div v-if="(proj.mode === 'KYC' || proj.mode === 'STRICT') && hasSignee" class="form-check form-switch mb-2">
                                    <input class="form-check-input" type="checkbox" v-model="inSequence" :disabled="docStatus !== 'DRAFT'">
                                    <span class="text-grey">Sort signees, sign document according to the sequence order.</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-end">
                                <button type="button" class="btn btn-outline-secondary me-2" data-bs-dismiss="modal">
                                    <fa icon="xmark" class="me-2" />Close
                                </button>
                                <button v-if="(proj.mode === 'KYC' || proj.mode === 'STRICT') && signeeOptToggle === 'signee'" type="button" @click="updSigneeOrder" class="btn btn-secondary" :disabled="docStatus !== 'DRAFT'" data-bs-dismiss="modal">
                                    <fa icon="floppy-disk" size="lg" class="me-2" />Save
                                </button>
                                <button v-if="(proj.mode === 'KYC' || proj.mode === 'STRICT') && signeeOptToggle === 'workflow'" type="button" class="btn btn-secondary" :disabled="docStatus !== 'DRAFT'" data-bs-dismiss="modal">
                                    <fa icon="floppy-disk" size="lg" class="me-2" />Save
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div><!-- modal-content -->
        </div><!-- modal-dialog --> 
    </div><!-- Modal End-->

    <!-- Modal: Select Doc --> 
    <div class="modal fade" id="mdlDoc" tabindex="-1" aria-labelledby="ShowDoc" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Select Document <span class="badge bg-grey"><fa icon="file" /> {{docs.length}}</span></h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="row px-1" >
                        <div class="col-12" style="height: 250px; overflow-y: auto">
                            <div class="list-group">
                                <a href="#" @click="changeDoc(event, d.documentId)" v-for="d in docs" :key="d.documentId" class="list-group-item list-group-item-action " :class="docId === d.documentId ? 'list-item-active': ''">
                                    <DocStatus :status="d.status" :shred="d.shred" class="" />{{ (d.name.replace('.pdf', '').length > 40 ? d.name.substring(0, 40) + '...' : d.name.replace('.pdf', '')) }}
                                    <span v-if="docId === d.documentId" class="float-end"><fa icon="check" size="lg" /></span>
                                    <Popper class="popperDark" arrow hover content="Signee">
                                        <span v-if="d.jSignee" class="badge bg-grey isTooltipsLabel ms-1"><fa icon="user" /> {{d.jSignee.length}}</span>
                                    </Popper>
                                </a>
                            </div> 
                        </div> 
                    </div> 
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div><!-- Modal End -->

    <!-- Modal: API params -->
    <div class="modal fade" id="mdlShowJson" tabindex="-1" aria-labelledby="ShowJson" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">API Params <span class="badge bg-grey">{{docs.length}}</span></h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="row px-1" >
                        <div class="col-12">
                            <textarea class="form-control" rows="16" ref="jsonObj" id="jsonObj" style="border-width: 0px; color: grey"></textarea>
                        </div>
                    </div> 
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button type="button" class="btn btn-secondary" @click="copy">
                        <fa icon="code" size="lg" class="me-2" /> Copy
                    </button>
                </div>
            </div>
        </div>
    </div><!-- Modal End -->

    <!-- Modal: API params -->
    <div class="modal fade" id="mdlReview" tabindex="-1" aria-labelledby="mdlReview" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Review</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="row px-1" >
                        <div v-if="boxes !== dbBoxes" class="col-12 mb-3">Oops, forgot to save your work?</div>
                        <div v-if="boxes === dbBoxes" class="col-12 mb-3">Click on Review button below will proceed to Review page.</div>
                        <div v-if="isSaveLoading === true" class="col-12"><fa icon="spinner" size="lg" class="me-2" />Loading...</div>
                    </div> 
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button v-if="boxes !== dbBoxes" type="button" class="btn btn-outline-secondary" @click="$router.push('/reviewInvite')" data-bs-dismiss="modal">
                        <fa icon="envelope-open" size="lg" class="me-2" /> Continue without Save
                    </button>
                    <button v-else type="button" class="btn btn-secondary" @click="$router.push('/reviewInvite')" data-bs-dismiss="modal">
                        <fa icon="envelope-open" size="lg" class="me-2" /> Review
                    </button>
                    <button v-if="boxes !== dbBoxes" type="button" class="btn btn-secondary" @click="saveDoc" >
                        <fa icon="floppy-disk" size="lg" class="me-2" /> Save
                    </button>
                </div>
            </div>
        </div>
    </div><!-- Modal End -->

</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { ref, computed, watch, inject, onMounted, onUnmounted, onUpdated } from 'vue'
import DragBox from '@/components/DragBox.vue' 
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import { Tooltip } from 'bootstrap/dist/js/bootstrap.js'
import Popper from 'vue3-popper'
import draggable from 'vuedraggable'
import { useRoute, useRouter } from 'vue-router'
import DocStatus from '@/components/DocStatus.vue'
import funcs from '@/functions/function'
// import Avatar from '@/components/Avatar.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'
import Step from '@/components/Step.vue'

let pdfjsDoc = null // for pdfjslib

// LocalStorage: signatoryDoc, signatoryCtrl, signatorySignee (combine with workflow, workflow got extra isRole & step)
export default {
    name: 'Signatory',
    components: { Alert, TopNavigation, degrees, PDFDocument, rgb, StandardFonts, Tooltip, Popper, draggable, DocStatus, ImgAvatar, Step },
    setup () {
        const showDebug = ref(false)
        
        const axios = inject('axios')
        const alert = ref([])
        const func = funcs
        const route = useRoute()
        const router = useRouter()

        const url = ref('') // https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf, https://pdf-lib.js.org/assets/with_update_sections.pdf
        const urlEncode = ref('')

        const isSignOn = ref(false) // detect if from signon Entity
        const isWorkflow = ref(true) // enable workflow module
        const isSaveLoading = ref(null)

        const showStep1Box = ref(true) 
        const showStep2Box = ref(true) 
        const showStep3Box = ref(true)
        const showStep4Box = ref(true) 
        
        const proj = ref({})
        const showToolbox = ref(true)
        const toolboxBtnIcon = ref(null)
        const contentClass = ref('col-12 p-0')
        const showAttr = ref(false)
        const showReview = ref(true)
        const docs = ref([])    // Docs excluded REMOVED
        const doc = ref(null)   // localStorage doc.json value
        const docSignees = ref([])  // doc Signees and objects
        const docId = ref(null)     // Doc drop down menu, current selected document
        const docName = ref(null)
        const docStatus = ref(null)
        const docShred = ref(null)
        const signeeOptToggle = ref('signee') // toggle the signee / workflow tabs in select signee modal
        const signeeOpt = ref('signee') // when click item below signee / workflow tabs
        const signees = ref([]) // project signees
        const signeeClasses = ref(["color0", "color1", "color2", "color3", "color4", "color5", "color6", "color7", "color8", "color9", "color10"])
        const signee = ref(null)    // selected signee info
        const signeeId = ref(null)  // selected signee
        const signeeSelectedClass = ref(null)
        const signeeName = ref(null)
        const orderNum = ref(1)
        const hasSignee = ref(false)    // true - has set at least signee
        const totalDocSignee = ref(0)   // total signee in doc
        const projOwner = ref(null)
        const projEntity = ref(null)
        const isEncrypted = ref(false)  // if document is encrypted

        const textboxNum = ref(1)
        const textareaNum = ref(1)
        const checkboxNum = ref(1)

        const workflowId = ref(null)
        const workflowTitle = ref(null)
        const workflowSignee = ref(null)
        const workflowSigneeId = ref(null)
        const workflowSigneeIsRole = ref(null)
        const workflowSigneeStep = ref(null)
        const arrWorkflow = ref([])
        const arrWorkflowSignee = ref([])

        const objId = ref(0)    // object id must be unique in doc, unique index num to generate object id, format: signeeId + objId
        const boxes = ref([])   // all signees' boxes in doc
        const docBoxes = ref([])    // cur signee boxes in doc (all pages)
        const pgBoxes = ref([])     // cur signee boxes in current page
        const dbBoxes = ref(null)   // all signees' boxes in doc (from DB)

        const inSequence = ref(false)
        const x = ref(0)
        const y = ref(0)

        const box = ref(null)
        const jsonObj = ref('')
        const mandatory = ref(true)
        const fontFamily = ref('Helvetica')
        const fontSize = ref(10)
        const fontSizeRange = ref([])
        const fontLabel = ref(null)
        const fontPlaceholder = ref(null)
        const isBold = ref(false)
        const isItalic = ref(false)
        const isUnderline = ref(false)
        const dateSignedFormat = ref('DD-MM-YYYY')
        const excludedPages = ref([])
        const maxChar = ref(0)
        const lstFontFamily = ref(null)
        const arrFontFamily = ref([])

        const pdfWidth = ref(0)
        const pdfHeight = ref(0)
        const isPdfLoaded = ref(false)
        const page = ref(1)
        const pageCount = ref(1)
        const goToPage = ref(1)
        const editPage = ref(false)
        const randKey = ref(1)
        const scale = ref(1)

        const browserWidth = ref(0) // browser width
        const docWidth = ref(0) //pt

        const objLSProj = ref({})
        const mdlSignee = ref(null)


        for (var i = 7; i < 19; i++) {
            if (i%2 == 0) {
                fontSizeRange.value.push(i)
            }
        }

        // default color
        signeeSelectedClass.value = signeeClasses.value[0]

        const test_modify = async () => {
            const existingPdfBytes = await fetch(url.value).then(res => res.arrayBuffer())
            const pdfDoc = await PDFDocument.load(existingPdfBytes)
            const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

                // Get the first page of the document
                const pages = pdfDoc.getPages()
                const firstPage = pages[0]

                // Get the width and height of the first page
                const { width, height } = firstPage.getSize()

                // Draw a string of text diagonally across the first page
                // y = pdfHeight - font size - y_coordinate
                firstPage.drawText('XXXXXX Name', {
                    x: 88.89,
                    y: pdfHeight.value - 10 - 37.1,
                    size: 8,
                    font: helveticaFont,
                    color: rgb(0.95, 0.1, 0.1),
                    rotate: degrees(0),
                })
                // color: rgb(0.95, 0.1, 0.1),

                // Serialize the PDFDocument to bytes (a Uint8Array)
                const pdfBytes = await pdfDoc.save()
                // download(pdfBytes, "modified.pdf", "application/pdf")
                // const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true })
                // this.$refs.mypdf.src = pdfDataUri

                // download file
                /* const blob = new Blob([pdfBytes], { type: "application/pdf" })
                const link = document.createElement("a")
                link.href = URL.createObjectURL(blob)
                link.download = 'modified.pdf'
                link.click()
                URL.revokeObjectURL(link.href) */

                // console.info('pageSize', width + ' | ' + height)

                // Show Changed result to website
                url.value = pdfBytes
        }

        watch([fontFamily, fontSize, isBold, isItalic, fontLabel, fontPlaceholder, mandatory, maxChar, excludedPages], async () => {
            // console.info('WATCH: font attr change -- hide')

            if (showAttr.value === true && box.value.type === 'textbox' && box.value.maxChar <= 0) {
                maxChar.value = 50
            }

            if (showAttr.value === true && box.value.type === 'textarea' && box.value.maxChar <= 0) {
                maxChar.value = 250
            }
           
            // Deploy font family & font size in drop down menu selected option
            if (showAttr.value === true && lstFontFamily.value) {
                // console.info('- - - WATCH: font attr change ')
                setTimeout(() => {
                    lstFontFamily.value.style.fontSize = fontSize.value + 'pt'
                }, 50)
                
                if (isBold.value === true) {
                    setTimeout(() => {
                        lstFontFamily.value.style.fontWeight = 'bold'
                    }, 50)
                } else {
                    setTimeout(() => {
                        lstFontFamily.value.style.fontWeight = 'normal'
                    }, 50)
                }

                if (isItalic.value === true) {
                    setTimeout(() => {
                        lstFontFamily.value.style.fontStyle = 'italic'
                    }, 50)
                } else {
                    setTimeout(() => {
                        lstFontFamily.value.style.fontStyle = 'normal'
                    }, 50)
                }

                if (fontFamily.value === 'Courier' || fontFamily.value === 'Helvetica' || fontFamily.value === 'TimesRoman') {
                    setTimeout(() => {
                        lstFontFamily.value.style.fontFamily = fontFamily.value
                    }, 50)

                }

            }

            boxes.value.forEach((item, idx) => {
                if (item.signeeId === signeeId.value && item.jObject) {
                    item.jObject.forEach((obj, objIdx) => {
                        if (obj.id === box.value.id) {
                            boxes.value[idx].jObject[objIdx].label = fontLabel.value
                            boxes.value[idx].jObject[objIdx].placeholder = fontPlaceholder.value
                            boxes.value[idx].jObject[objIdx].isMandatory = mandatory.value
                            boxes.value[idx].jObject[objIdx].format.font = fontFamily.value
                            boxes.value[idx].jObject[objIdx].format.size = fontSize.value
                            boxes.value[idx].jObject[objIdx].format.bold = isBold.value
                            boxes.value[idx].jObject[objIdx].format.italic = isItalic.value
                            boxes.value[idx].jObject[objIdx].excludedPages = excludedPages.value
                            boxes.value[idx].jObject[objIdx].maxChar = maxChar.value

                            box.value = boxes.value[idx].jObject[objIdx]
                            jsonObj.value = JSON.stringify(box.value)
                        }
                    })
                }
            })

            setTimeout(()  => loadBoxesObjects(), 100)
        })

        watch([url], async () => {
            clearAnnotation()
            setTimeout(() => {
                readDoc(url.value)
            }, 1000)
        })

        watch([page, scale], async ([newPage, newScale], [oldPage, oldScale]) => {
            // console.info('---- watch page/scale')
            // call readPage to render viewport

            localStorage.setItem('scale', scale.value)
            clearAnnotation()
            readPage(page.value, scale.value)
        })

        watch([workflowId], () => {
            if (workflowId.value === 1001) {
            workflowTitle.value = 'WorkFlow Testing Number One'
            }

            if (workflowId.value === 1002) {
                workflowTitle.value = 'Testing 2'
            }

            arrWorkflowSignee.value = [
                { "isRole": true, "signeeId": "Developer", "name": "Developer", "email": "", "node": "node1" }, 
                { "isRole": false, "signeeId": "DED17C76E24F11EC89D6FB54B2801234", "name": "Enerson", "email": "enerson.yap@genusis.com", "node": "node3" }, 
                { "isRole": true, "signeeId": "Support", "name": "Support", "email": "", "node": "node4" }, 
                { "isRole": true, "signeeId": "CS", "name": "CS", "email": "", "node": "node5" }, 
                { "isRole": true, "signeeId": "Developer", "name": "Developer", "email": "", "node": "node6" }, 
                { "isRole": false, "signeeId": "6E7A09F8E24E11EC89D6FB54B2806B93", "name": "Arshad", "email": "arshad@genusis.com", "node": "node7" }, 
                { "isRole": false, "signeeId": "DED17C76E24F11EC89D6FB54B2801234", "name": "Enerson", "email": "enerson.yap@genusis.com", "node": "node8" }, 
                { "isRole": true, "signeeId": "Support", "name": "Support", "email": "", "node": "node9" }, 
                { "isRole": true, "signeeId": "CS", "name": "CS", "email": "", "node": "node10" }
            ]
        })

        const reviseDoc = () => {
            console.info('revise', docId.value)
        }

        const readDoc = async (url) => {
            // console.info('- - readDoc')
            isPdfLoaded.value = false

            const existingPdfBytes = await fetch(url, { credentials: 'include' }).then(res => res.arrayBuffer())
            // const pdfDoc = await PDFDocument.load(existingPdfBytes)
            const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true })
            const pdfVal =  await pdfDoc.saveAsBase64({ dataUri: true })

            /* WORKING: pass in new URL method
            const existingPdfBytes = await fetch(url, { credentials: 'include' }).then(res => res.arrayBuffer())
            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
            if (window.navigator['msSaveOrOpenBlob']) {
                window.navigator['msSaveBlob'](blob, filename)
            }
            else {
                const newurl =  window.URL.createObjectURL(blob)
                console.info('newurl', newurl)

                urlEncode.value = Pdfvuer.createLoadingTask(newurl)
                urlEncode.value.then(pdf => {
                    pageCount.value = pdf.numPages
                    isPdfLoaded.value = true
                })
            } */

            /* ORIG: 
            const loadingTask = pdfjsLib.getDocument(pdfVal)
            loadingTask.promise.then((doc) => {
                pdfjsDoc = doc

                // console.info('- - readDoc', doc)
                pageCount.value = doc.numPages
                readPage(page.value, scale.value)
                isPdfLoaded.value = true
            }) */

            const loadingTask = pdfjsLib.getDocument({ url: pdfVal })
            loadingTask.promise.then((doc) => {
                pdfjsDoc = doc
                
                pageCount.value = doc.numPages
                readPage(page.value, scale.value)
                isPdfLoaded.value = true

            },(error) => {
                isPdfLoaded.value = true

                if (error.name === 'PasswordException') {
                    console.info('The PDF is password-protected and no password was provided.')
                } else {
                    console.info('ERROR loading the PDF:', error)
                }
            })
        }
  
        const readPage = async (pageNum, pdfScale) => {

            if(pdfjsDoc) 
            {
                console.info('read', isEncrypted.value)
                const page = await pdfjsDoc.getPage(pageNum)
                // console.info('page', page)
                const viewport = page.getViewport({ scale: pdfScale * 1.33333 })

                const containerDiv = document.getElementById('pdfBoxContainer')
                if (containerDiv !== null) {
                    containerDiv.style.width = viewport.width + 'px'
                    containerDiv.style.height = viewport.height + 'px'
                }

                const canvas = document.getElementById('pdf-canvas')
                const context = canvas.getContext('2d')
                const resolution = window.devicePixelRatio || 1

                // console.info('canvas: pdfBoxContainer', canvas)
                canvas.width = Math.floor(viewport.width) * resolution
                canvas.height = Math.floor(viewport.height) * resolution
                canvas.style.width = Math.floor(viewport.width) + 'px'
                canvas.style.height = Math.floor(viewport.height) + 'px'

                context.scale(resolution, resolution)

                const transform = [1, 0, 0, 1, 0, 0]

                // Render PDF page into canvas context
                const renderContext = {
                    canvasContext: context,
                    transform,
                    viewport,
                }
                page.render(renderContext)
                // const opList = await page.getOperatorList()

                // Anotation layer width and height shd identical as pdf
                const element = document.getElementById('pdf-annotation-layer')
                element.width = Math.floor(viewport.width) * resolution
                element.height = Math.floor(viewport.height) * resolution
                element.style.width = Math.floor(viewport.width) + 'px'
                element.style.height = Math.floor(viewport.height) + 'px'
             
                const annots = await page.getAnnotations()
                for (const annot of annots) {
                    if(annot && annot.subtype === 'Link') {
                        renderAnnotationLink(annot, viewport, resolution, element)
                    } 
                }
            }
        }

        const renderAnnotationLink = (annotation, viewport, resolution, elAnnoLayer) => {
            const scale = viewport.scale

            const [x0, y0, x1, y1] = annotation.rect
            const yDiff = (y1 - y0) * scale
            const x = x0 * scale
            const y = Math.floor(viewport.height) - ( y0 * scale) - yDiff
            const width = (x1 - x0) * scale
            const height = (y1 - y0) * scale

            var lnk = window.document.createElement('a')
            lnk.href = annotation.url
            lnk.style.position = 'absolute'
            lnk.style.left = `${x}px`
            lnk.style.top = `${y}px`
            lnk.style.width = `${width}px`
            lnk.style.height = `${height}px`
            // lnk.style.border = '1px solid red'
            // lnk.style.backgroundColor = 'rgba(255, 255, 0, 0.5)'
            // lnk.style.pointerEvents = 'none'
            // lnk.style.innerText = annotation.title

            elAnnoLayer.appendChild(lnk)
        }

        const clearAnnotation = async () => {
            const container = document.getElementById('pdf-canvas')
            if (container !== null) {
                while (container.firstChild) {
                    container.removeChild(container.firstChild)
                }
            }

            const container2 = document.getElementById('pdf-annotation-layer')
            if (container2 !== null) {
                while (container2.firstChild) {
                    container2.removeChild(container2.firstChild)
                }
            }
        }
        
        const changeFontFamily = () => {
            const selectElement = document.querySelector('select');

            if (fontFamily.value === 'Courier' || fontFamily.value === 'Helvetica' || fontFamily.value === 'TimesRoman') {
                selectElement.style.fontFamily = fontFamily.value
            }

            randKey.value = Math.floor(Math.random() * 1000)
        }

        const changeFontSize = () => {
            const selectElement = document.querySelector('select')
            selectElement.style.fontSize = fontSize.value + 'pt'

            randKey.value = Math.floor(Math.random() * 1000)
        }

        const setBold = () => {
            isBold.value = !isBold.value

            const selectElement = document.querySelector('select')
            if (isBold.value === true) {
                selectElement.style.fontWeight = 'bold'
            } else {
                selectElement.style.fontWeight = 'normal'
            }

            randKey.value = Math.floor(Math.random() * 1000)
        }

        const setItalic = () => {
            isItalic.value = !isItalic.value

            const selectElement = document.querySelector('select');
            if (isItalic.value === true) {
                selectElement.style.fontStyle = 'italic'
            } else {
                selectElement.style.fontStyle = 'normal'
            }

            randKey.value = Math.floor(Math.random() * 1000)
        }

        const getResize = (s, t, id, w, h) => {
            
            boxes.value.forEach((item, idx) => {
                // console.info('- - item.signeeId', item.signeeId)
                if (item.signeeId === s) {
                    item.jObject.forEach((obj, objIdx) => {
                        if (obj.id === id) {
                            boxes.value[idx].jObject[objIdx].width = w
                            boxes.value[idx].jObject[objIdx].height = h
                            
                        }
                    })
                }
            })
        }

        const refreshBox = () => {
            randKey.value = Math.floor(Math.random() * 1000)
        }

        const getWorkflow = () => {
            arrWorkflow.value = [
                {
                    id: 1001,
                    title: 'WorkFlow Testing Number One'
                },
                {
                    id: 1002, 
                    title: 'Testing 2'
                },
            ]
        }

        const selectWorkflowSignee = (signeeIdx, signeeId, signeeName, isRole) => {
            signeeOpt.value = 'workflow'
            signeeOptToggle.value = 'workflow'

            let step = signeeIdx + 1

            workflowSignee.value = signeeName
            workflowSigneeId.value = signeeId
            workflowSigneeIsRole.value = isRole
            workflowSigneeStep.value = step

            signeeSelectedClass.value = signeeClasses.value[signeeIdx]

            let objLSSignee = { opt: 'workflow', id: signeeId, name: signeeName, class: signeeClasses.value[signeeIdx], isRole: isRole, step: step } 
            localStorage.setItem('signatorySignee', func.encodeJsonToStrSimple(objLSSignee))

            // worflowId, workflowTitle
            // signeeId, signeeName, isRole
        }

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const add = async (type1, y1) => {
            if (docBoxes.value.length === 0) {
                objId.value = 1

            } else {
                let lastIdx = docBoxes.value.length - 1
                let lastId = docBoxes.value[lastIdx].id
                let lastNum = lastId.replace(signeeId.value, '')
                lastNum = lastNum * 1
                objId.value = lastNum + 1
            }

            // Add 1st object
            if (boxes.value.length === 0) {
                // console.info('---- 0')
                if (type1 === 'comboSignature') {
                    boxes.value.push({ signeeId: signeeId.value, isRole: false, seq: 0, jObject:[{id: signeeId.value + objId.value, type: type1, label: '', placeholder: '', isMandatory: mandatory.value, format: { font: 'Helvetica', size: 10, bold: false, italic: false }, position: {page: goToPage.value, x: 255, y: y1}, combo: ['cFullLegalName', 'cDesignation', 'cOrganization'], width: 0, height: 0, maxChar: 0 }] })
                    showAttr.value = false
                } else if (type1 === 'textarea') {
                    boxes.value.push({ signeeId: signeeId.value, isRole: false, seq: 0, jObject:[{id: signeeId.value + objId.value, type: type1, label: 'Textarea' + textareaNum.value, placeholder: '', isMandatory: mandatory.value, format: { font: 'Helvetica', size: 10, bold: false, italic: false }, position: {page: goToPage.value, x: 255, y: y1}, combo: {}, width: 280, height: 32, maxChar: 250}] })
                    getBox(signeeId.value, 255, y1, signeeId.value, 'Textarea' + textareaNum.value, '', mandatory.value, 250, goToPage.value, type1, 'Helvetica', 10, false, false, 200, 32, '', '', '', '', '', '', '')
                } else if (type1 === 'textbox') {
                    boxes.value.push({ signeeId: signeeId.value, isRole: false, seq: 0, jObject:[{id: signeeId.value + objId.value, type: type1, label: 'Textbox' + textboxNum.value, placeholder: '', isMandatory: mandatory.value, format: { font: 'Helvetica', size: 10, bold: false, italic: false }, position: {page: goToPage.value, x: 255, y: y1}, combo: {}, width: 0, height: 0, maxChar: 50}] })
                    getBox(signeeId.value, 255, y1, signeeId.value, 'Textbox' + textboxNum.value, '', mandatory.value, 50, goToPage.value, type1, 'Helvetica', 10, false, false, 0, 0, '', '', '', '', '', '', '')
                } else if (type1 === 'checkbox') {
                    boxes.value.push({ signeeId: signeeId.value, isRole: false, seq: 0, jObject:[{id: signeeId.value + objId.value, type: type1, label: 'Checkbox' + checkboxNum.value, placeholder: '', isMandatory: mandatory.value, format: { font: 'Helvetica', size: 10, bold: false, italic: false }, position: {page: goToPage.value, x: 255, y: y1}, combo: {}, width: 0, height: 0, maxChar: 0}] })
                    getBox(signeeId.value, 255, y1, signeeId.value, 'Checkbox' + checkboxNum.value, '', mandatory.value, 0, goToPage.value, type1, 'Helvetica', 10, false, false, 0, 0, '', '', '', '', '', '', '')
                } else {   
                    boxes.value.push({ signeeId: signeeId.value, isRole: false, seq: 0, jObject:[{id: signeeId.value + objId.value, type: type1, label: '', placeholder: '', isMandatory: mandatory.value, format: { font: 'Helvetica', size: 10, bold: false, italic: false }, position: {page: goToPage.value, x: 255, y: y1}, combo: {}, width: 0, height: 0, maxChar: 0}] })
                    if(type1 === 'fullSignature' || type1 === 'stamp') {
                        showAttr.value = false
                    } else {    
                        getBox(signeeId.value, 255, y1, signeeId.value, '', '', mandatory.value, 0, goToPage.value, type1, 'Helvetica', false, false, 10, 0, 0, '', '', '', '', '', '', '')
                    }
                }
            } else {
                let signeeExist = false
                boxes.value.forEach((b1, bIdx1) => {
                    if (b1.signeeId === signeeId.value && boxes.value[bIdx1].jObject) {
                        signeeExist = true
                        if (type1 === 'comboSignature') {
                            boxes.value[bIdx1].jObject.push({id: signeeId.value + objId.value, type: type1, label: '', placeholder: '', isMandatory: mandatory.value, format: { font: 'Helvetica', size: 10, bold: false, italic: false }, position: {page: goToPage.value, x: 255, y: y1}, combo: ['cFullLegalName', 'cDesignation', 'cOrganization'], width: 0, height: 0, maxChar: 0})
                            showAttr.value = false
                        } else if (type1 === 'textarea') {
                            boxes.value[bIdx1].jObject.push({id: signeeId.value + objId.value, type: type1, label: 'Textarea' + textareaNum.value, placeholder: '', isMandatory: mandatory.value, format: { font: 'Helvetica', size: 10, bold: false, italic: false }, position: {page: goToPage.value, x: 255, y: y1}, combo: {}, width: 280, height: 32, maxChar: 250})
                            getBox(signeeId.value + objId.value, 255, y1, signeeId.value, 'Textarea' + textareaNum.value, '', mandatory.value, 250, goToPage.value, type1, 'Helvetica', 10, false, false, 200, 32, '', '', '', '', '', '', '')
                        } else if (type1 === 'textbox') {
                            boxes.value[bIdx1].jObject.push({id: signeeId.value + objId.value, type: type1, label: 'Textbox' + textboxNum.value, placeholder: '', isMandatory: mandatory.value, format: { font: 'Helvetica', size: 10, bold: false, italic: false }, position: {page: goToPage.value, x: 255, y: y1}, combo: {}, width: 0, height: 0, maxChar: 50})
                            getBox(signeeId.value + objId.value, 255, y1, signeeId.value, 'Textbox' + textboxNum.value, '', mandatory.value, 50, goToPage.value, type1, 'Helvetica', 10, false, false, 0, 0, '', '', '', '', '', '', '')
                        } else if (type1 === 'checkbox') {
                            boxes.value[bIdx1].jObject.push({id: signeeId.value + objId.value, type: type1, label: 'Checkbox' + checkboxNum.value, placeholder: '', isMandatory: mandatory.value, format: { font: 'Helvetica', size: 10, bold: false, italic: false }, position: {page: goToPage.value, x: 255, y: y1}, combo: {}, width: 0, height: 0, maxChar: 0})
                            getBox(signeeId.value + objId.value, 255, y1, signeeId.value, 'Checkbox' + checkboxNum.value, '', mandatory.value, 0, goToPage.value, type1, 'Helvetica', 10, false, false, 0, 0, '', '', '', '', '', '', '')
                        } else {
                            boxes.value[bIdx1].jObject.push({id: signeeId.value + objId.value, type: type1, label: '', placeholder: '', isMandatory: mandatory.value, format: { font: 'Helvetica', size: 10, bold: false, italic: false }, position: {page: goToPage.value, x: 255, y: y1}, combo: {}, width: 0, height: 0, maxChar: 0})
                            if(type1 === 'fullSignature' || type1 === 'stamp') {
                                showAttr.value = false
                            } else {    
                                getBox(signeeId.value + objId.value, 255, y1, signeeId.value, '', '', mandatory.value, 0, goToPage.value, type1, 'Helvetica', 10, false, false, 0, 0, '', '', '', '', '', '', '')
                            }
                        }
                    }
                })

                if(!signeeExist) {
                    if (type1 === 'comboSignature') {
                        boxes.value.push({ signeeId: signeeId.value, isRole: false, seq: 0, jObject:[{id: signeeId.value + objId.value, type: type1, label: '', placeholder: '', isMandatory: mandatory.value, format: { font: 'Helvetica', size: 10, bold: false, italic: false }, position: {page: goToPage.value, x: 255, y: y1}, combo: ['cFullLegalName', 'cDesignation', 'cOrganization'], width: 0, height: 0, maxChar: 0}] })
                        showAttr.value = false
                    } else if (type1 === 'textarea') {
                        boxes.value.push({ signeeId: signeeId.value, isRole: false, seq: 0, jObject:[{id: signeeId.value + objId.value, type: type1, label: 'Textarea' + textareaNum.value, placeholder: '', isMandatory: mandatory.value, format: { font: 'Helvetica', size: 10, bold: false, italic: false }, position: {page: goToPage.value, x: 255, y: y1}, combo: {}, width: 280, height: 32, maxChar: 250}] })
                        getBox(signeeId.value, 255, y1, signeeId.value, 'Textarea' + textareaNum.value, '', mandatory.value, 250, goToPage.value, type1, 'Helvetica', 10, false, false, 200, 32, '', '', '', '', '', '', '')
                    } else if (type1 === 'textbox') {
                        boxes.value.push({ signeeId: signeeId.value, isRole: false, seq: 0, jObject:[{id: signeeId.value + objId.value, type: type1, label: 'Textbox' + textboxNum.value, placeholder: '', isMandatory: mandatory.value, format: { font: 'Helvetica', size: 10, bold: false, italic: false }, position: {page: goToPage.value, x: 255, y: y1}, combo: {}, width: 0, height: 0, maxChar: 50}] })
                        getBox(signeeId.value, 255, y1, signeeId.value, 'Textbox' + textboxNum.value, '', mandatory.value, 50, goToPage.value, type1, 'Helvetica', 10, false, false, 0, 0, '', '', '', '', '', '', '')
                    } else if(type1 === 'checkbox') {
                        boxes.value.push({ signeeId: signeeId.value, isRole: false, seq: 0, jObject:[{id: signeeId.value + objId.value, type: type1, label: 'Checkbox' + checkboxNum.value, placeholder: '', isMandatory: mandatory.value, format: { font: 'Helvetica', size: 10, bold: false, italic: false }, position: {page: goToPage.value, x: 255, y: y1}, combo: {}, width: 0, height: 0, maxChar: 0}] })
                        getBox(signeeId.value, 255, y1, signeeId.value, 'Checkbox' + checkboxNum.value, '', mandatory.value, 0, goToPage.value, type1, 'Helvetica', 10, false, false, 0, 0, '', '', '', '', '', '', '')
                    } else {
                        boxes.value.push({ signeeId: signeeId.value, isRole: false, seq: 0, jObject:[{id: signeeId.value + objId.value, type: type1, label: '', placeholder: '', isMandatory: mandatory.value, format: { font: 'Helvetica', size: 10, bold: false, italic: false }, position: {page: goToPage.value, x: 255, y: y1}, combo: {}, width: 0, height: 0, maxChar: 0}] })
                        if(type1 === 'fullSignature' || type1 === 'stamp') {
                            showAttr.value = false
                        } else {    
                            getBox(signeeId.value, 255, y1, signeeId.value, '', '', mandatory.value, 0, goToPage.value, type1, 'Helvetica', 10, false, false, 0, 0, '', '', '', '', '', '', '')
                        }
                    }
                }
            }
            dbBoxes.value = func.decodeStrToJsonSimple(localStorage.getItem('signatoryDbBoxes'))

            hasSignee.value = true
            setTimeout(() => loadBoxesObjects(), 1000)

            updDocsSignee()
            setProjectSigneeObj(false)
        }

        const updDocsSignee = () => {
            let addDocsSignee = false
            if (docs.value.length > 0) {
                docs.value.forEach((item, idx) => {

                    if (item.documentId === docId.value) {
                        if (item.jSignee && item.jSignee.length > 0) {
                        let isExist = false
                            item.jSignee.forEach((sItem, sIdx) => {
                                if (sItem.signeeId === signeeId.value) {
                                    isExist = true
                                }
                            })

                            if (!isExist) {
                            addDocsSignee = true
                            }

                        } else {
                            addDocsSignee = true
                        }

                        if (addDocsSignee) {
                            docs.value[idx].jSignee.push({ name: signeeName.value, email: signeeId.value, signeeId: signeeId.value})
                        }
                    }
                    
                })

            } //docs > 0
        }

        const newBox = computed(() => {
            return DragBox
        })

        const getPosition = (showAttr1, id1, x1, y1, signee1, label1, placeholder1, mandatory1, maxChar1, page1, type1, fontFamily1, fontSize1, fontBold1, fontItalic1, width1, height1, excludedPages1, comboFullLegalName1, comboNRIC1, comboPassport1, comboDesignation1, comboOrganization1, comboDateSigned1) => {
            
            // console.info('- - getPosition', showAttr1, fontSize1, fontFamily1, x1, y1)

            showAttr.value = showAttr1
            dbBoxes.value = func.decodeStrToJsonSimple(localStorage.getItem('signatoryDbBoxes'))
            x.value = x1
            y.value = y1

            
            
            boxes.value.forEach((item, idx) => {
                // console.info('- - getPosition item', JSON.stringify(item))
                if (item.signeeId === signee1 && item.jObject) {
                    item.jObject.forEach((obj, objIdx) => {
                        if (obj.id === id1) {

                            if (type1 === 'textarea') {
                                boxes.value[idx].jObject[objIdx] = { id: id1, type: type1, label: label1, placeholder: placeholder1, isMandatory: mandatory1, maxChar: maxChar1, format: { font: fontFamily1, size: fontSize1, bold: fontBold1, italic: fontItalic1}, position: { page: page1, x: x1, y: y1}, combo: {}, width: width1, height: height1}
                            
                            } else if (type1 === 'comboSignature') {
                                boxes.value[idx].jObject[objIdx] = { id: id1, type: type1, label: label1, placeholder: placeholder1, isMandatory: mandatory1, maxChar: 0, format: { font: fontFamily1, size: fontSize1, bold: fontBold1, italic: fontItalic1}, position: { page: page1, x: x1, y: y1}, combo: [], width: 0, height: 0}

                                if (comboFullLegalName1 === true) {
                                    boxes.value[idx].jObject[objIdx].combo.push('cFullLegalName')
                                }
                                if (comboDesignation1 === true) {
                                    boxes.value[idx].jObject[objIdx].combo.push('cDesignation')
                                }
                                if (comboOrganization1 === true) {
                                    boxes.value[idx].jObject[objIdx].combo.push('cOrganization')
                                }
                                if (comboNRIC1 === true) {
                                    boxes.value[idx].jObject[objIdx].combo.push('cNric')
                                }
                                if (comboPassport1 === true) {
                                    boxes.value[idx].jObject[objIdx].combo.push('cPassport')
                                }
                                if (comboDateSigned1 === true) {
                                    boxes.value[idx].jObject[objIdx].combo.push('cDateSigned')
                                }

                            } else if (type1 === 'textbox'){
                                boxes.value[idx].jObject[objIdx] = { id: id1, type: type1, label: label1, placeholder: placeholder1, isMandatory: mandatory1, maxChar: maxChar1, format: { font: fontFamily1, size: fontSize1, bold: fontBold1, italic: fontItalic1}, position: { page: page1, x: x1, y: y1}, combo: {}, width: 0, height: 0}
                            
                            } else {
                                boxes.value[idx].jObject[objIdx] = { id: id1, type: type1, label: label1, placeholder: placeholder1, isMandatory: mandatory1, maxChar: 0, format: { font: fontFamily1, size: fontSize1, bold: fontBold1, italic: fontItalic1}, position: { page: page1, x: x1, y: y1}, combo: {}, width: 0, height: 0}
                            }
                            //20220610 if (type1 !== 'initial' && type1 !== 'fullSignature') {
                            if (showAttr.value === true && type1 !== 'fullSignature' && type1 !== 'comboSignature') {
                                getBox(id1, x1, y1, signee1, label1, placeholder1, mandatory1, maxChar1, page1, type1, fontFamily1, fontSize1, fontBold1, fontItalic1, width1, height1, excludedPages1, comboFullLegalName1, comboNRIC1, comboPassport1, comboDesignation1, comboOrganization1, comboDateSigned1)
                            }
                        }
                    })
                }
            })
            setTimeout(() => loadBoxesObjects(), 500)

        }


        const getBox = (id1, x1, y1, signee1, label1, placeholder1, mandatory1, maxChar1, page1, type1, fontFamily1, fontSize1, fontBold1, fontItalic1, width1, height1, excludedPages1, comboFullLegalName1, comboNRIC1, comboPassport1, comboDesignation1, comboOrganization1, comboDateSigned1) => {
            // Trigger when change position or click dragbox - to show attribute/property section
            // console.info('getBox', id1, x1, y1, signee1, label1, placeholder1, mandatory1, page1, type1, fontFamily1, fontSize1)
            // console.info('--- getBox', 'fontsize', fontSize1, 'showAttr', showAttr.value)
            // console.info('--- getBox', showAttr.value)
            
            dbBoxes.value = func.decodeStrToJsonSimple(localStorage.getItem('signatoryDbBoxes'))

            if(showAttr.value === true) {
                // console.info('------ getBox: true', fontFamily1, fontSize1)
                box.value = { id: id1, type: type1, label: label1, placeholder: placeholder1, isMandatory: mandatory1, maxChar: maxChar1, format:{font: fontFamily1, size: fontSize1, bold: fontBold1, italic: fontItalic1}, position:{page: page1, x:x1, y: y1}, excludedPages: excludedPages1, combo: [], width: width1, height: height1}
     
                if (type1 === 'comboSignature') {
                    if (comboFullLegalName1 === true) {
                        box.value.combo.push('cFullLegalName')
                    }
                    if (comboDesignation1 === true) {
                        box.value.combo.push('cDesignation')
                    }
                    if (comboOrganization1 === true) {
                        box.value.combo.push('cOrganization')
                    }
                    if (comboNRIC1 === true) {
                        box.value.combo.push('cNric')
                    }
                    if (comboPassport1 === true) {
                        box.value.combo.push('cPassport')
                    }
                    if (comboDateSigned1 === true) {
                        box.value.combo.push('cDateSigned')
                    }
                }

                mandatory.value = mandatory1
                maxChar.value = maxChar1
                fontLabel.value = label1
                fontPlaceholder.value = placeholder1
                fontFamily.value = fontFamily1
                fontSize.value = fontSize1
                isBold.value = fontBold1
                isItalic.value = fontItalic1
                excludedPages.value = excludedPages1

                if(lstFontFamily.value) {
                    lstFontFamily.value.style.fontFamily = fontFamily1
                    lstFontFamily.value.style.fontSize = fontSize1 + 'pt'
                }
            }
            
        }

        const delBox = async (id1) => {
            // console.info('delBox', id1)
            dbBoxes.value = func.decodeStrToJsonSimple(localStorage.getItem('signatoryDbBoxes'))
            
            docBoxes.value.forEach((item, idx) => {
                if (item.id === id1) {
                    docBoxes.value.splice(idx, 1)
                }
            })

            let delSignee = false
            boxes.value.forEach((item, idx) => {
                if (item.signeeId === signeeId.value) {
                    if (item.jObject && item.jObject.length > 0) {
                        item.jObject.forEach((item1, idx1) => {
                            if (item1.id === id1) {
                                boxes.value[idx].jObject.splice(idx1, 1)
                            }
                        })
                    }
                    // delete signee with empty object
                    if (item.jObject && item.jObject.length === 0) {
                        delSignee = true
                        boxes.value.splice(idx, 1)
                    }
                }
            })

            // delete signee with empty object in docSignees
            if (delSignee === true) {
                docSignees.value.forEach((item, idx) => {
                    if (item.signeeId === signeeId.value) {
                        docSignees.value.splice(idx, 1)
                    }
                })
            }

            // update totalObj in signees to reflect total object in select signee modal
            signees.value.forEach((item, idx) => {
                if (item.signeeId === signeeId.value) {
                    item.totalObj = item.jObject.length
                }
            })

            await getDocBoxesToPgBoxes()
            randKey.value = Math.floor(Math.random() * 1000)
            closeAttr()
        }

        const updPageNum = (page1) => {
            goToPage.value = page1
            getDocBoxesToPgBoxes()
        }

        const toggleToolbox = async () => {
            // location.reload() - Hide due to use floating toolbar which no need detect size */
            showToolbox.value = !showToolbox.value

            let objCtrl = { leftDrawer: showToolbox.value, hasClickToolbox: true }
            localStorage.setItem('signatoryCtrl', func.encodeJsonToStrSimple(objCtrl))
        }
            
        const closeAttr = () => {
            let proceed = true

            if (box.value !== null && boxes.value[0] && boxes.value[0].jObject.length > 0) {
                boxes.value[0].jObject.forEach((item, idx) => {
                    if (item.id === box.value.id && item.label === '' && 
                        (item.type === 'textbox' || item.type === 'textarea' || item.type === 'checkbox')) {
                        proceed = false
                    }
                })

                if (proceed) {
                    showAttr.value = false
                    box.value = null    //reset box

                } else {
                    alert.value.push({
                        class: "info",
                        title: "INFO",
                        message: "Please enter Name in " + box.value.type +" attribute"
                    })
                }
            }

        }

        const getFieldPage = (field) => {
            // console.info('pages: docBoxes', JSON.stringify(docBoxes.value))

            let pages = []
            docBoxes.value.forEach((item, idx) => {
                
                if (item.type === field) {
                    if (!pages.includes(item.position.page)) {
                        pages.push(item.position.page*1)
                    }
                }
            })

            if (pages.length > 0) {
                pages.sort()
            }
         
            return pages
        }

        const sortSignee = () => {
            // console.info('* * * * sortSignee func', JSON.stringify(signees.value))
            
            let newSeq = 1;
            signees.value.forEach((s, sIdx) => {
            if (s.totalObj > 0) {
                boxes.value.forEach((b, bIdx) => {
                    if (b.signeeId === s.signeeId) {
                        boxes.value[bIdx].seq = newSeq
                        newSeq = newSeq + 1
                    }
                })
            }
            })
        }
        
        const selectSignee = async (id, index, name, email, e) => {

            // validations: standard field must fill with name before select other signee
            let proceed = true
            textboxNum.value = 1
            textareaNum.value = 1
            checkboxNum.value = 1

            boxes.value.forEach((b, bIdx) => {
                if (b.signeeId === signeeId.value) {
                    if (boxes.value[bIdx] && boxes.value[bIdx].jObject.length > 0) {
                        boxes.value[bIdx].jObject.forEach((item, idx) => {

                            if (item.type === 'textbox') {
                                textboxNum.value = textboxNum.value + 1
                            }

                            if (item.type === 'textarea') { 
                                textareaNum.value = textareaNum.value + 1
                            }

                            if (item.type === 'checkbox') {
                                checkboxNum.value = checkboxNum.value + 1
                            }
                            
                            if ((item.type === 'textbox' || item.type === 'textarea' || item.type === 'checkbox') && 
                                item.label === '') {
                                proceed = false
                            }
                        })
                    }
                }
            })

            if (proceed === false) {

                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: "Missing Name for some of your Textbox, Textarea or Checkbox object. Please enter the Name accordingly for '" + signeeName.value + "' then save it before select next signee."
                })

            } else {

                signeeOpt.value = 'signee'
                signeeOptToggle.value = 'signee'

                signeeId.value = id
                signeeSelectedClass.value = signeeClasses.value[index]
                signeeName.value = name
                signee.value = e

                let objLSSignee = { opt: 'signee', id: id, name: name, class: signeeClasses.value[index] } 
                localStorage.setItem('signatorySignee', func.encodeJsonToStrSimple(objLSSignee))

                // Update old signee object to boxes
                // Get new signee to docBoxes
                let hasMatched = false
                boxes.value.forEach((item, idx) => {
                if (item.signeeId === id) {
                    hasMatched = true
                    docBoxes.value = item.jObject
                }
                })

                if (!hasMatched) {
                docBoxes.value = []
                }

                // sort pgBoxes
                // setTimeout(() => getDocBoxesToPgBoxes(), 500)
                await getDocBoxesToPgBoxes()
                randKey.value = Math.floor(Math.random() * 1000)
            }
        }

        const updSigneeOrder = async() => {
            if (inSequence.value === false || inSequence.value === 'false') {
                boxes.value.forEach((b, bIdx) => {
                    boxes.value[bIdx].seq = 0
                })
            } else if (inSequence.value === true || inSequence.value === 'true') {

                sortSignee()
            }

            signeeOpt.value = 'signee'
            setTimeout(() => loadBoxesObjects(), 500)
            setTimeout(() => saveDoc(), 1000)
        }

        const setProjectSigneeObj = async (setDefaultSignee) => {
            // everyone are signees (viewer, signee, owner, editor)
        
            signees.value.forEach((projSignee, projIdx, arr) => {
                signees.value[projIdx].jObject = []
                signees.value[projIdx].totalObj = 0

                if (docSignees.value.length > 0) {
                    docSignees.value.forEach((item, idx) => {

                        if (projSignee.signeeId === item.signeeId) {
                            signees.value[projIdx].seq = item.seq
                            signees.value[projIdx].jObject = item.jObject

                            if (item.jObject && item.jObject.length > 0) {
                                signees.value[projIdx].totalObj = item.jObject.length
                                hasSignee.value = true
                            }
                        } 
                    })
                }
            })


            let tmpSignees = []
            tmpSignees = signees.value
            signees.value = []

            if (docSignees.value.length > 0 && inSequence.value === true) {
                // sort signee based on seq set in doc
                let arrExist = []

                for (var num = 1; num <= docSignees.value.length; num++ ) {
                    docSignees.value.forEach((s, idx) => {
                    if (s.seq === num) {
                        signees.value.push(s)
                        arrExist.push(s.signeeId)
                    }
                    })
                }

                tmpSignees.forEach((s, idx) => {
                    if ( arrExist.includes(s.signeeId) === false) {
                        signees.value.push(s)
                    }
                })

            } else {

                // console.info('- - setProjectSigneeObj II - empty doc signees')
                tmpSignees.forEach((s, idx) => {
                    // console.info('* signee seq:', s.seq, s.signeeId, 'totalObj:', s.totalObj)
                    if (s.totalObj > 0) {
                        signees.value.push(s)
                    }
                })

                tmpSignees.forEach((s, idx) => {
                    if (s.totalObj === 0) {
                        signees.value.push(s)
                    }
                })
            }

            if (setDefaultSignee) {
                if (signeeId.value === null) {
                    if (localStorage.getItem('signatorySignee') === null ) {

                        // Set default signee
                        signeeId.value = signees.value[0].signeeId
                        signeeName.value = signees.value[0].signeeName
                        signee.value = signees.value[0]
                    
                        let objLSSignee = { opt: 'signee', id: signees.value[0].signeeId, name: signees.value[0].signeeName, class: signeeClasses.value[0] } 
                        localStorage.setItem('signatorySignee', func.encodeJsonToStrSimple(objLSSignee))

                    } else {
                        
                        let objLSSignee = func.decodeStrToJsonSimple(localStorage.getItem('signatorySignee'))
                        signeeId.value = objLSSignee.id
                        signeeName.value = objLSSignee.name

                        // Check signee order to get color
                        signees.value.forEach((s, sIdx) => {
                            if (s.signeeId === signeeId.value) {
                                signeeSelectedClass.value = signeeClasses.value[sIdx]
                            }
                        })
                    }
                    
                }

                if(signees.value.length === 1) {
                    showStep2Box.value = false
                }

            } else {
                if (signeeId.value !== null && signeeId.value !== '') {
                    // Check signee order to get color
                    signees.value.forEach((s, sIdx) => {
                        if (s.signeeId === signeeId.value) {
                            signeeSelectedClass.value = signeeClasses.value[sIdx]
                        }
                    })
                }
            }
        }

        const changeDoc = async (event, documentId) => {
            // console.info('----- changeDoc', documentId, page.value)
            // validations: standard field must fill with name before select other signee
            showAttr.value = false
            let proceed = true

            boxes.value.forEach((b, bIdx) => {
                if (b.signeeId === signeeId.value && boxes.value[bIdx].jObject) {
                    if (boxes.value[bIdx] && boxes.value[bIdx].jObject.length > 0) {
                        boxes.value[bIdx].jObject.forEach((item, idx) => {
    
                            if ((item.type === 'textbox' || item.type === 'textarea' || item.type === 'checkbox') && 
                                item.label === '') {
                                proceed = false
                            }
                        })
                    }
                }
            })

            if (proceed === false) {
            
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: "Missing Name for some of your Textbox, Textarea or Checkbox object. Please enter the Name accordingly for '" + signeeName.value + "' then save it before select next document."
                })

            } else {

                isPdfLoaded.value = false
                docId.value = documentId

                docs.value.forEach((item) => {
                    
                    if (item.documentId === docId.value) {
                        docName.value = item.name
                        url.value = item.viewUrl
                        goToPage.value = 1
                        page.value = 1

                        let objDoc = { id: docId.value, name: item.name, url: item.viewUrl }
                        localStorage.setItem('signatoryDoc', func.encodeJsonToStrSimple(objDoc))

                        boxes.value = []
                        docBoxes.value = []
                        pgBoxes.value = []
                    }
                })

                await getDocument(docId.value, false)
                await setDbBoxes(boxes.value)
                await setProjectSigneeObj(false)
                await loadBoxesObjects()
                await refreshPgBoxes()  // Reload dragbox component purpose or dragboxes not reload when same signee selected for different doc issue
                
            }
        }

        const refreshPgBoxes = () => {

            // Reload dragbox component purpose or dragboxes not reload when same signee selected for different doc issue
            const tmpPgBoxes = pgBoxes.value
            pgBoxes.value = []

            setTimeout(() => {
                pgBoxes.value = tmpPgBoxes
                return pgBoxes.value
            }, 500)
        }

        const saveDoc = async () => {

            isSaveLoading.value = true

            // validation
            let proceed = true
            let errType = 0
            let errField = ''
            boxes.value.forEach((b, bIdx) => {
                if (b && b.jObject && b.jObject.length > 0) {
                    b.jObject.forEach((item, idx) => {
                        if ((item.type === 'textbox' || item.type === 'textarea' || item.type === 'checkbox') && 
                            item.label === '') {
                            proceed = false
                            errType = 1

                        } else if (item.type === 'textbox' && (item.maxChar === 0 || item.maxChar === '0' || item.maxChar === '' || item.maxChar === null || item.maxChar === undefined || item.maxChar > 50)) {
                            proceed = false
                            errType = 2
                            errField = item.label

                        } else if (item.type === 'textarea' && (item.maxChar === 0 || item.maxChar === '0' || item.maxChar === '' || item.maxChar === null || item.maxChar === undefined || item.maxChar > 250)) {
                            proceed = false
                            errType = 3
                            errField = item.label
                        }

                    })

                }

            })
            
            if (proceed == false) {
                
                if (errType === 2)
                {
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: "The maximum character / length for textbox: " + errField + " is 50."
                    })
                } 
                else if (errType === 3)
                {
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: "The maximum character / length for textarea: " + errField + " is 250."
                    })
                }
                else
                {
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: "Missing Name for some of your Textbox, Textarea or Checkbox object. Please enter the Name accordingly."
                    })
                }
                isSaveLoading.value = false

            } else {
                showAttr.value = false

                let saved = false
                try {
                    let p = {
                        isSort: inSequence.value === undefined ? false : inSequence.value,
                        jSignee: boxes.value
                    }
                    console.info('saveDoc', JSON.stringify(p))

                    const res = await axios.put('/signon/' + objLSProj.value.id + '/' + docId.value, p)
                    if (res.data.status === 1) {
                        saved = true
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Document is saved."
                        })

                        await setDbBoxes(boxes.value)

                        isSaveLoading.value = false

                    } else {
                        isSaveLoading.value = false

                        func.addLog('signatory', 'saveDoc', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'signature_is_required') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'Signature is compulsory'
                                })

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }

                        }

                    }

                } catch (error) {
                    boxes.value = await convObj('ui', boxes.value)
                    isSaveLoading.value = false

                    func.addLog('signatory', 'saveDoc - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                }

                if (saved === true) {
                    getTotalDocSignee(docId.value)

                }

            }

        }

        const getProject = async (id) => {

            return axios.get( '/signon/' + id).then((res) => {

                if (res.data.status === 1) {
                    proj.value = res.data.data
                    projOwner.value = res.data.data.jCreator.fullName
                    projEntity.value = res.data.data.jEntity.entityName
                    // console.info('proj', JSON.stringify(res.data.data))

                    // Update latest project local storage
                    let objLSProj = { id: res.data.data.folderId, name: res.data.data.folderName, status: res.data.data.status}
                    localStorage.setItem('project', func.encodeJsonToStrSimple(objLSProj))

                    if (res.data.data.jEntity.entityId === '1') {
                        isSignOn.value = true

                    }

                    if (typeof proj.value.jDocument === "object" && proj.value.jDocument !== null && proj.value.jDocument !== undefined) {
                        for(var i in proj.value.jDocument) {
                            if (proj.value.jDocument[i].status !== 'DELETE') {
                                docs.value.push(proj.value.jDocument[i])
                            }
                        }

                        if (docId.value === null) {
                            if (localStorage.getItem('signatoryDoc') === null) {
                                // Set default document
                                docId.value = docs.value[0].documentId
                                docName.value = docs.value[0].name
                                url.value = docs.value[0].viewUrl
                                
                                let objDoc = { id: docs.value[0].documentId, name: docs.value[0].name, url: url.value }
                                localStorage.setItem('signatoryDoc', func.encodeJsonToStrSimple(objDoc))

                            } else {
                                if (func.isEmptyObject(localStorage.getItem('signatoryDoc')) === false) {
                                    let objDoc = func.decodeStrToJsonSimple(localStorage.getItem('signatoryDoc'))
                                    docId.value = objDoc.id
                                    docName.value = objDoc.name
                                    url.value = objDoc.url

                                }
                            }
                        }

                        if(docs.value.length === 1) {
                            showStep1Box.value = false
                        }

                    }

                } else {
                    func.addLog('signatory', 'getProject', res)

                    if (res && res.data !== null && res.data !== undefined) {

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })
                            
                    }
                }

            })
            .catch((error) => {
                func.addLog('signatory', 'getProject - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

            })
        }
        

        const getDocument = (id, isSetUrl) => {

            return axios.get( '/signon/' + objLSProj.value.id + '/' + id)
                .then((res) => {
                    
                    if (res.data.status === 1) {
                        
                        // console.info('docname', JSON.stringify(res.data.data))
                        pdfWidth.value = res.data.data.jFile.pageWidth * 1.33333
                        pdfHeight.value = res.data.data.jFile.pageHeight * 1.33333
                        pageCount.value = res.data.data.jFile.totalPage
                        docWidth.value = res.data.data.jFile.pageWidth

                        isEncrypted.value = res.data.data.jFile.isEncrypted

                        if (isSetUrl === false) {
                            url.value = res.data.data.jFile.viewUrl

                        }

                        docStatus.value = res.data.data.status
                        docShred.value = res.data.data.shred

                        doc.value = { 
                            isSort: res.data.data.isSort, 
                            signees: res.data.data.jSignee
                        }

                        docSignees.value = res.data.data.jSignee
                        inSequence.value = res.data.data.isSort
                        totalDocSignee.value = res.data.data.jSignee.length

                        // console.info('getdoc here ' + objLSProj.value.id + '/' + id, res.data.data.jSignee)

                        // remove jAvater, status etc not importatnt param from jSignee
                        const tmpBox = res.data.data.jSignee
                        if (tmpBox.length > 0) {
                            tmpBox.forEach((sItem, sIdx) => {
                                boxes.value.push({ signeeId: sItem.signeeId, isRole: sItem.isRole, seq: sItem.seq, jObject: sItem.jObject})
                            })
                        }

                    } else {

                        func.addLog('signatory', 'getDocument', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'document_not_found') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'Document not found.'
                                })
                        
                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }

                        }

                    }

                })
                .catch((error) => {
                    func.addLog('signatory', 'getDocument - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })

        }

        const getTotalDocSignee = (docId) => {
            axios.get( '/signon/' + objLSProj.value.id + '/' + docId)
            .then((res) => {

                if (res.data.status === 1) {
                    totalDocSignee.value = res.data.data.jSignee.length

                } else {
                    func.addLog('signatory', 'getTotalDocSignee', res)

                    if (res && res.data !== null && res.data !== undefined) {

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })
                            
                    }
                }
            })
            .catch((error) => {
                func.addLog('signatory', 'getTotalDocSignee - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

            })
        }

        const loadBoxesObjects = async () => {
            // Apply boxes object to docBoxes & pgBoxes
            // Apply boxes object to signees & docSignees object 

            docBoxes.value = []
            pgBoxes.value = []
            textboxNum.value = 1
            textareaNum.value = 1
            checkboxNum.value = 1

            // console.info('boxes', JSON.stringify(boxes.value))
            boxes.value.forEach((item) => {
                
                if (item.signeeId === signeeId.value) {
                    if (item.jObject && item.jObject.length > 0) {
                        item.jObject.forEach((obj, objIdx) => {
                            docBoxes.value.push(obj)
                     
                            if (obj.type === 'textbox') {
                                textboxNum.value = textboxNum.value + 1
                            }

                            if (obj.type === 'textarea') { 
                                textareaNum.value = textareaNum.value + 1
                            }

                            if (obj.type === 'checkbox') {
                                checkboxNum.value = checkboxNum.value + 1
                            }
                            
                            if (obj.position.page === goToPage.value) {
                                pgBoxes.value.push(obj)

                            }
                        })

                    }

                }

                // Update signees object from boxes
                signees.value.forEach((itemSignee, idxSignee) => {
                    if (itemSignee.signeeId === item.signeeId) {
                        signees.value[idxSignee].jObject = item.jObject
                        if (signees.value[idxSignee].jObject !== undefined && signees.value[idxSignee].jObject.length > 0) {
                            signees.value[idxSignee].totalObj = signees.value[idxSignee].jObject.length
                        }
                    }
                })

                // Update docSignees object from boxes
                docSignees.value.forEach((itemDSignee, idxDSignee) => {
                    if (itemDSignee.signeeId === item.signeeId) {
                        docSignees.value[idxDSignee].jObject = item.jObject
                    }
                })

            })

        }

        const getDocBoxesToPgBoxes = () => {
            // Get pgBoxes from docBoxes
            // Upd signees object changes to signees (proj) & docSignees
            pgBoxes.value = []
            docBoxes.value.forEach((item, idx) => {
                if (item.position.page === goToPage.value) {
                    pgBoxes.value.push(item)
                }
            })

            // console.info('- -  getDocBoxesToPgBoxes pgBoxes', JSON.stringify(pgBoxes.value))
            hasSignee.value = false
            signees.value.forEach((itemSignee, idxSignee) => {
                if (itemSignee.signeeId === signeeId.value) {
                    signees.value[idxSignee].jObject = docBoxes.value
                    signeeSelectedClass.value = signeeClasses.value[idxSignee] // Set selected class color after sorting of signee, viewOnly, notAccess
                    signees.value[idxSignee].mode = itemSignee.mode

                }

                if (signees.value[idxSignee].jObject.length > 0) {
                    hasSignee.value =  true

                }
            })

            docSignees.value.forEach((itemDC, idxDC) => {
                if (itemDC.signeeId === signeeId.value) {
                    docSignees.value[idxDC].jObject = docBoxes.value

                }
            })

        }

        const viewJson = () => {
            document.getElementById('jsonObj').value = '' + JSON.stringify(box.value, undefined, 2) + ' '

        }

        const copy = () => {
            document.getElementById('jsonObj').select()
            document.execCommand('copy')

        }

        const zoomIn = () => {
            // console.info('zoomIn +')
            showAttr.value = false
            scale.value = parseFloat((scale.value + 0.1).toFixed(1))
            randKey.value = Math.floor(Math.random() * 1000)

        }

        const zoomOut = async () => {
            // console.info('zoomOut -')
            showAttr.value = false
            scale.value = parseFloat((scale.value - 0.1).toFixed(1))
            randKey.value = Math.floor(Math.random() * 1000)
        }

        const zoomDefault = async () => {
            // console.info('zoomDef')
            showAttr.value = false
            scale.value = 1
            randKey.value = Math.floor(Math.random() * 1000)
        }

        const navigatePage = async (opt) => {
            if (opt === 'prev')
            {
                page.value = page.value - 1
                goToPage.value = page.value

            } else if (opt === 'next') {
                page.value++
                goToPage.value = page.value

            } else if (opt === 'first') {
                page.value = 1
                goToPage.value = page.value

            } else if (opt === 'last') {
                page.value = pageCount.value
                goToPage.value = page.value     

            } else if (opt !== '' && opt > 0) {
                page.value = opt * 1
                goToPage.value = opt * 1  

            } else {
                goToPage.value = goToPage.value * 1
                if (goToPage.value > 0 && goToPage.value <= pageCount.value) {
                    page.value = goToPage.value

                } else { 
                    page.value = 1
                    goToPage.value = page.value

                }

            }
            showAttr.value = false
            randKey.value = Math.floor(Math.random() * 1000)

        }
       
        const selectText = (event) => {
            event.target.select()
        }

        const iniScale = async () => {
            console.info('iniScale', 'browserWidth:', browserWidth.value, docWidth.value)

            if (browserWidth.value <= 380) {
                // cater for mobile phone
                scale.value = 0.4

            } else if (browserWidth.value <= 428) {
                // cater for mobile phone
                scale.value = 0.5

            } else if (browserWidth.value <= 820) {
                // cater for tablet
                scale.value = 0.7

            } else {
                if (docWidth.value) {
                    let pdfWidth = docWidth.value * 1.33333.toFixed(5) * 1 //convert docWidth from pt to px
                    let pdfBox = browserWidth.value - 255 // deduct toolbar

                    let newScale = scale.value
                    let newPdfWidth = pdfWidth

                    for(var tmpScale = 1; tmpScale <= 2; tmpScale+=0.1) {
                        tmpScale = tmpScale.toFixed(1) * 1
                        newPdfWidth = pdfWidth * tmpScale

                        if (newPdfWidth <= pdfBox) {
                            newScale = tmpScale
                        }
                    }

                    scale.value = newScale
                    console.info('final scale: ' + newScale, 'pdfWidth', pdfWidth)

                } 
            }
        }

        onMounted(() => {
            
            localStorage.setItem('scale', scale.value)
            browserWidth.value = window.innerWidth

            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome to SignOn."
                })

            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
                if (route.query.l) { 
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                    })

                }

            }

            getWorkflow()
            if (func.isEmptyObject(localStorage.getItem('project')) === false) {
                objLSProj.value = func.decodeStrToJsonSimple(localStorage.getItem('project'))

            }

            showToolbox.value = true
            if (localStorage.getItem('signatoryCtrl') === null) {
                let objCtrl = { leftDrawer: true, hasClickToolbox: false }
                localStorage.setItem('signatoryCtrl', func.encodeJsonToStrSimple(objCtrl))

            } else {
                if (func.isEmptyObject(localStorage.getItem('signatoryCtrl')) === false) {
                    let objCtrl = func.decodeStrToJsonSimple(localStorage.getItem('signatoryCtrl'))
                    if (objCtrl.hasClickToolbox === true || objCtrl.hasClickToolbox === 'true') {
                        localStorage.setItem('signatoryCtrl', func.encodeJsonToStrSimple({ leftDrawer: objCtrl.leftDrawer, hasClickToolbox: true }))

                    } else {
                        localStorage.setItem('signatoryCtrl', func.encodeJsonToStrSimple({ leftDrawer: objCtrl.leftDrawer, hasClickToolbox: false }))

                    }

                }

            }

            if (showToolbox.value) {
                toolboxBtnIcon.value = "chevron-left"

            } else {
                toolboxBtnIcon.value = "chevron-right"

            }
            iniLoadPdf()
            setTimeout(() => {
                iniScale()
            }, 500)
        })

        const iniLoadPdf = async () => {
            if (localStorage.getItem('signatoryCtrl') === null) {
                let objCtrl = { leftDrawer: false, hasClickToolbox: false }
                localStorage.setItem('signatoryCtrl', func.encodeJsonToStrSimple(objCtrl))

                showToolbox.value = false
                toolboxBtnIcon.value = "chevron-right"

            } else {
                if (objLSProj.value.id) {
                    if (func.isEmptyObject(localStorage.getItem('signatoryCtrl')) === false) {
                        let objCtrl = func.decodeStrToJsonSimple(localStorage.getItem('signatoryCtrl'))
                        showToolbox.value = objCtrl.leftDrawer
                        toolboxBtnIcon.value = "chevron-left"

                    }

                } else {
                    showToolbox.value = false

                }

            }

            // getWorkflow()
            if (objLSProj.value.id) {
                await getProject(objLSProj.value.id)
                await getDocument(docId.value, true)
                await getProjectSignees(objLSProj.value.id)
                await setProjectSigneeObj(true)
                await loadBoxesObjects()
                await setDbBoxes(boxes.value)

            } 

        }

        const setDbBoxes = (b) => {
            dbBoxes.value = []
            dbBoxes.value = b
            localStorage.setItem('signatoryDbBoxes', func.encodeJsonToStrSimple(b))

        }

        const calWinTop = (val) => {
            console.info('calWinTop', val, scale.value)
        }
       
        function getSigneeClass(signee1) {
            for (var i = 0; i < signees.value.length; i++) {
                if (signee1 === signees.value[i].signeeId) {
                    return signeeClasses.value[i]

                }

            }

        }

        function getProjectSignees(id) {
            return axios.get('/signon/' + id + '/signee/list'
                ).then((res) => {
                    
                    if (res.data.status === 1) {
                        signees.value = res.data.data

                    } else {
                        func.addLog('signatory', 'getProjectSignees', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                                
                        }

                    }
                })
                .catch((error) => {
                    func.addLog('signatory', 'getProjectSignees - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        return { 
            showDebug, alert, closeAlert, func, route, router, toggleToolbox, showToolbox, contentClass, toolboxBtnIcon, closeAttr, showAttr, 
            newBox, box, boxes, docBoxes, pgBoxes, add, getPosition, delBox, inSequence, isWorkflow, isSignOn, dbBoxes, isSaveLoading,
            url, urlEncode, objLSProj, totalDocSignee, mdlSignee, 
            fontFamily, fontSize, fontSizeRange, isBold, isItalic, isUnderline, fontLabel, fontPlaceholder, excludedPages, mandatory, maxChar, dateSignedFormat,
            proj, docs, doc, docSignees, docId, docName, docStatus, 
            signees, signee, signeeId, signeeClasses, signeeSelectedClass, getSigneeClass, signeeName,  objId, 
            updSigneeOrder, getProject, getDocument, changeDoc, setProjectSigneeObj, 
            selectSignee, sortSignee, orderNum, saveDoc, updPageNum, getDocBoxesToPgBoxes, getFieldPage,
            pdfWidth, pdfHeight, hasSignee, copy, jsonObj, viewJson, 
            signeeOpt, signeeOptToggle, arrWorkflow, arrWorkflowSignee, selectWorkflowSignee,
            workflowId, workflowTitle, workflowSignee, workflowSigneeId, workflowSigneeIsRole, workflowSigneeStep, showReview, 
            page, pageCount, goToPage, navigatePage, randKey,
            showStep1Box, showStep2Box, showStep3Box, showStep4Box, getResize, textboxNum, textareaNum, checkboxNum, editPage, 
            isPdfLoaded, lstFontFamily, changeFontFamily, changeFontSize, arrFontFamily, refreshBox,
            projOwner, projEntity, setBold, setItalic, scale, zoomIn, zoomOut, zoomDefault, selectText, calWinTop, docShred, reviseDoc, isEncrypted,
            browserWidth, docWidth
        }
    },
    data () {
        return {
            winTop: 0,
        }
    },
    mounted () {
        
        // Initiate tooltip
        Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode))
        Array.from(document.querySelectorAll('span[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode))

        let scale = localStorage.getItem('scale')*1
        let element = this.$refs.pdfBoxContainer
        if (element !== null && element !== undefined) {
            // ORIG: this.winTop = parseFloat(element.getBoundingClientRect().top).toFixed(2) * -1 + 150
            // this.winTop = parseFloat(element.getBoundingClientRect().top).toFixed(2) * -1 + 500

            if (scale >= 1 && scale <= 1.2) {
                this.winTop = parseFloat(element.getBoundingClientRect().top).toFixed(2) * -1 + 500
            } else if (scale >= 1.3 && scale <= 1.4) { 
                this.winTop = parseFloat(element.getBoundingClientRect().top).toFixed(2) * -1 + 300
            } else if (scale >= 1.5 && scale <= 1.6) { 
                this.winTop = parseFloat(element.getBoundingClientRect().top).toFixed(2) * -1
                if(this.winTop <= 0) {
                    this.winTop = this.winTop + 200
                } else {
                    this.winTop = this.winTop + 50
                }
            } else if (scale >= 1.7 && scale <= 1.8) { 
                this.winTop = parseFloat(element.getBoundingClientRect().top).toFixed(2) * -1
                if(this.winTop <= 0 ) {
                    this.winTop = this.winTop + 150
                } else {
                    this.winTop = this.winTop - 200
                }
            
            } else if (scale >= 1.9 && scale <= 2.0) { 
                this.winTop = parseFloat(element.getBoundingClientRect().top).toFixed(2) * -1
                if(this.winTop <= 0 ) {
                    this.winTop = this.winTop + 200
                } else {
                    this.winTop = this.winTop - 350
                }
            } else {
                this.winTop = parseFloat(element.getBoundingClientRect().top).toFixed(2) * -1 + 500
            }
        }
        
        window.addEventListener("scroll", this.handleScroll, true)
    },  
    methods: {
        
        handleScroll() {
            let element = this.$refs.pdfBoxContainer
            if (element !== null && element !== undefined) {
                // ORIG: this.winTop = parseFloat(element.getBoundingClientRect().top).toFixed(2) * -1 + 150
                let scale = localStorage.getItem('scale')*1
                if (scale >= 1 && scale <= 1.2) {
                    this.winTop = parseFloat(element.getBoundingClientRect().top).toFixed(2) * -1 + 500
                } else if (scale >= 1.3 && scale <= 1.4) { 
                    this.winTop = parseFloat(element.getBoundingClientRect().top).toFixed(2) * -1 + 300
                } else if (scale >= 1.5 && scale <= 1.6) { 
                    // this.winTop = parseFloat(element.getBoundingClientRect().top).toFixed(2) * -1 + 180
                    this.winTop = parseFloat(element.getBoundingClientRect().top).toFixed(2) * -1
                    if(this.winTop <= 0) {
                        this.winTop = this.winTop + 200
                    } else {
                        this.winTop = this.winTop + 50
                    }

                } else if (scale >= 1.7 && scale <= 1.8) { 
                    
                    this.winTop = parseFloat(element.getBoundingClientRect().top).toFixed(2) * -1
                    if(this.winTop <= 0 ) {
                        this.winTop = this.winTop + 150
                    } else {
                        this.winTop = this.winTop - 200
                    }
                
                } else if (scale >= 1.9 && scale <= 2.0) { 
                    this.winTop = parseFloat(element.getBoundingClientRect().top).toFixed(2) * -1
                    if(this.winTop <= 0 ) {
                        this.winTop = this.winTop + 200
                    } else {
                        this.winTop = this.winTop - 350
                    }
                } else {
                    this.winTop = parseFloat(element.getBoundingClientRect().top).toFixed(2) * -1 + 500
                }

                // console.info('wintop=====', element.getBoundingClientRect().top, scale)
                // this.winTop = parseFloat(element.getBoundingClientRect().top).toFixed(2) * -1 + 500
                // this.winTop = parseFloat(element.getBoundingClientRect().top).toFixed(2) * -1 + (500*scale)
                // this.winTop = parseFloat(element.getBoundingClientRect().top).toFixed(2) * -1 * scale + 500
                
            } 
        },
    } // methods
}

/* 
NOTE: fontFamily,fontSize pass from parent to DragBox component thr props, other from component to parent thr getPosition
npm i vue3-draggable-resizable 
https://www.npmjs.com/package/vue3-draggable-resizable
npm i vue-pdf-embed
https://www.npmjs.com/package/vue-pdf-embed
npm i pdf-lib
https://www.npmjs.com/package/pdf-lib
npm i downloadjs

test https://www.npmjs.com/package/vue3-date-time-picker
https://vue3datepicker.com/installation/#global
npm i vue3-date-time-picker

References
https://www.designcise.com/web/tutorial/how-to-apply-css-opacity-to-background-color-only
scan signature https://www.youtube.com/watch?v=0ArXBk6vS5U 
*/
</script>

<style>
   
    body {
        color: #6E777E;
        font-size: 14px;
        overflow: hidden;
        /* padding-bottom: 100px; */
    }

    .pdfBg {
        background-image: url("../../assets/bgTrans1.png");
        width: 100%;
        height: 100%;
    }

    .box {
        border-style: dashed;
        border-width: 3px;
        cursor: pointer;
        border-radius: 5px;
    }

    .box:hover {
        border-style: dashed;
        border-width: 3px;
        cursor: move;
    }

    .box:active {
        cursor: move;
        border-style: dashed;
        border-width: 3px;
    }

    .boxSelected, .boxSelected:hover {
        border-width: 3px;
        border-style: solid;
    }

    .boxCal {
        padding-left: 8px;
        padding-right: 8px;
        width: 200px;
    }

    .boxDisabled {
        border-style: double;
        border-width: 3px;
        border-radius: 5px;
    }

    .boxDisabled:hover {
        border-style: double;
        border-width: 3px;
    }

    .boxDisabled:active {
        border-style: double;
        border-width: 3px;
    }

    @media (max-width: 575.98px) {
        .boxDisabled   {
            font-size: 7px;
        }

        .boxDisabled > div > svg, .boxSignInitial > div > svg, .boxSignInitialDef > div > svg {
            padding-top: 0px;
            padding-bottom: 0px;
            font-size: 7px;
        }

        .boxDisabled > div > span, .boxSignInitial > div > span, .boxSignInitialDef > div > span {
            padding-top: 0px;
            padding-bottom: 0px;
            font-size: 7px;
        }
    }

    .color0 {
        color: #1141AF;
        border-color: #1141AF;
    }

    .color0Bg {
        color: #1141AF;
        border-color: #1141AF;
        background-color: rgba(17, 65, 175, 0.2);
    }

    .color0Bg .form-check-input {
        color: #1141AF;
        border-color: #1141AF;
        background-color: #1141AF;
        width: 1.5em;
        height: 1.2em;
        margin-right: 5px;
    }

    .color1 {
        color: #CD0275;
        border-color: #CD0275;
    }

    .color1Bg {
        color: #CD0275;
        border-color: #CD0275;
        background-color: rgba(205, 2, 117, 0.2);
    }

    .color1Bg .form-check-input {
        color: #CD0275;
        border-color: #CD0275;
        background-color: #CD0275;
        width: 1.5em;
        height: 1.2em;
        margin-right: 5px;
    }

    .color2 {
        color: #00999D;
        border-color: #00999D;
    }

    .color2Bg {
        color: #00999D;
        border-color: #00999D;
        background-color: rgba(0, 153, 157, 0.2);
    }

    .color2Bg .form-check-input {
        color: #00999D;
        border-color: #00999D;
        background-color: #00999D;
        width: 1.5em;
        height: 1.2em;
        margin-right: 5px;        
    }

    .color3 {
        color: #FF7302;
        border-color:  #FF7302;
    }

    .color3Bg {
        color: #FF7302;
        border-color:  #FF7302;
        background-color: rgba(255, 115, 2, 0.2);
    }

    .color3Bg .form-check-input {
        color: #FF7302;
        border-color:  #FF7302;
        background-color:  #FF7302;
        width: 1.5em;
        height: 1.2em;
        margin-right: 5px;
    }

    .color4 {
        color: #AC6BC6;
        border-color:  #AC6BC6;
    }

    .color4Bg {
        color: #AC6BC6;
        border-color:  #AC6BC6;
        background-color: rgba(172, 107, 198, 0.2);
    }

    .color4Bg .form-check-input {
        color: #AC6BC6;
        border-color:  #AC6BC6;
        background-color:  #AC6BC6;
        width: 1.5em;
        height: 1.2em;
        margin-right: 5px;
    }

    .color5 {
        color: #967218;
        border-color:  #967218;
    }

    .color5Bg {
        color: #967218;
        border-color:  #967218;
        background-color: rgba(243, 200, 91, 0.2);
    }

    .color5Bg .form-check-input {
        color: #967218;
        border-color:  #967218;
        background-color:  #967218;
        width: 1.5em;
        height: 1.2em;
        margin-right: 5px;
    }

    .color6 {
        color: #7F7F7F;
        border-color:  #7F7F7F;
    }

    .color6Bg {
        color: #7F7F7F;
        border-color:  #7F7F7F;
        background-color: rgba(191, 191, 191, 0.2);
    }

    .color6Bg .form-check-input {
        color: #7F7F7F;
        border-color:  #7F7F7F;
        background-color:  #7F7F7F;
        width: 1.5em;
        height: 1.2em;
        margin-right: 5px;
    }

    .color7 {
        color: #DF0808;
        border-color:  #DF0808;
    }

    .color7Bg {
        color: #DF0808;
        border-color:  #DF0808;
        background-color: rgba(241, 137, 137, 0.2);
    }

    .color7Bg .form-check-input {
        color: #DF0808;
        border-color:  #DF0808;
        background-color:  #DF0808;
        width: 1.5em;
        height: 1.2em;
        margin-right: 5px;
    }

    .color8 {
        color: #1E90FF;
        border-color:  #1E90FF;
    }

    .color8Bg {
        color: #1E90FF;
        border-color:  #1E90FF;
        background-color: rgba(173, 216, 230, 0.2);
    }

    .color8Bg .form-check-input {
        color: #1E90FF;
        border-color:  #1E90FF;
        background-color:  #1E90FF;
        width: 1.5em;
        height: 1.2em;
        margin-right: 5px;
    }

    .color9 {
        color: #008000;
        border-color:  #008000;
    }

    .color9Bg {
        color: #008000;
        border-color:  #008000;
        background-color: rgba(142, 249, 142, 0.2);
    }

    .color9Bg .form-check-input {
        color: #008000;
        border-color:  #008000;
        background-color:  #008000;
        width: 1.5em;
        height: 1.2em;
        margin-right: 5px;
    }

    .color10 {
        color: #9B497E;
        border-color:  #9B497E;
    }
    
    .color10Bg {
        color: #9B497E;
        border-color:  #9B497E;
        background-color: rgba(237, 162, 211, 0.2);
    }

    .color10Bg .form-check-input {
        color: #9B497E;
        border-color:  #9B497E;
        background-color:  #9B497E;
        width: 1.5em;
        height: 1.2em;
        margin-right: 5px;
    }

    .handle {
        cursor: move;
    }

    .list-item-active {
        background-color: var(--navbar-light);
    }

    .list-group-item-action:hover, .list-group-item-action:focus {
        z-index: 1;
        color: var(--text-grey-link);
        text-decoration: none;
        background-color: var(--navbar-light);
    }

    .input-group-text {
        background-color: var(--bg-body-color);
    }

    .list-group-item {
        background-color: var(--toolbar-bg);
    }

    .toolbarFloat {
        position: fixed; 
        z-index: 2; 
        top: 41px;
        width: 255px; 
        opacity: 0.8; 
        background-color: var(--toolbar-bg);
    }

    .toolboxBtnClose {
        position: fixed; 
        margin-top: 50px;
        width: 20px; 
        height: 45px; 
        padding: 11px 2px; 
        background-color: grey;
        border-top-right-radius: 8px; 
        border-bottom-right-radius: 8px; 
        z-index: 9; 
        opacity: 0.8; 
        cursor: pointer;
    }

    .toolboxBtnOpen {
        position: fixed;
        margin-top: 50px;
        left: 255px; 
        width: 20px; 
        height: 45px; 
        padding: 11px 2px; 
        background-color: grey;
        border-top-right-radius: 8px; 
        border-bottom-right-radius: 8px; 
        z-index: 9; 
        opacity: 0.8; 
        cursor: pointer;
    }

    .card-header {
        padding: 0px;
        border-bottom: 0px;
        background-color: var(--toolbar-bg);
    }

    .card-header .btn {
        padding: 5px 10px;
        font-size: 14px;
        font-weight: 600;
    }

    .card-header .btn:focus {
        box-shadow: 0 0 0 0;
    }

    .card-body {
        padding: 5px 12px;
        background-color: var(--toolbar-bg);
    }

    .step-number {
        position: relative;
        display: flex;
        overflow: hidden;
        width: 25px;
        height: 25px;
        padding: 10px;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        border-radius: 9999px;
        color: #FFFFFF;
        line-height: 1;
        font-weight: 600;
        text-align: center;
        letter-spacing: 0;
        text-transform: uppercase;
        font-size: 11px;
        background-color: var(--bs-primary);
    }
</style>